import { Injectable } from '@angular/core';
import { CachePolicy, CachePolicyEntity, UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { DcBaseRequester } from './dc-base.requester';
import { IViewMembers } from './view-members.model';

@Injectable({
	providedIn: 'root',
})
export class DcViewRequester extends DcBaseRequester<IViewMembers> {
	protected resource: string;
	protected elementsListUrl;
	protected singleElementUrl;
	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('DC_VIEW_MEMBERS');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}

	public getViewMembers(): Observable<IViewMembers> {
		const url = this.urlService.generateUrl(this.resource, {}, {});
		const cachingPolicy = CachePolicyEntity.build({
			policy: CachePolicy.NetworkAndCache,
			evictTimeout: 60,
			isCacheable: true,
		});

		return this.get<IViewMembers>(url, cachingPolicy);
	}

	public getViewMembersOnProjectImport(): Observable<IViewMembers> {
		let url = this.generateResourceUrl('PROJECTS_IMPORT_VIEW_USERS');
		url = this.urlService.generateUrl(url, {}, {});
		const cachingPolicy = CachePolicyEntity.build({
			policy: CachePolicy.NetworkAndCache,
			evictTimeout: 60,
			isCacheable: true,
		});

		return this.get<IViewMembers>(url, cachingPolicy);
	}
}
