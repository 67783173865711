<ng-container *ngIf="vo$ | async; let vo">
	<dx-data-grid
		keyExpr="id"
		[elementAttr]="{
			id: 'exposition-consumer-list',
			class: 'app-exposition-consumer-list'
		}"
		[dataSource]="vo.list.elements | toArray"
		[hoverStateEnabled]="true"
		[showColumnHeaders]="true"
		[allowColumnResizing]="true"
		[columnAutoWidth]="true"
		[showRowLines]="false"
		[showColumnLines]="false"
		[rowAlternationEnabled]="false"
		[showBorders]="false"
		[width]="'100%'"
		(onSelectionChanged)="onSelectionChange($event)">
		<dxo-selection
			mode="multiple"
			[showCheckBoxesMode]="'always'"></dxo-selection>
		<dxo-load-panel
			shadingColor="rgba(0,0,0,0.4)"
			indicatorSrc="/src/img/loader-duotone.svg"
			[height]="100"
			[width]="100"
			[enabled]="true"
			[showIndicator]="true"
			[showPane]="true"
			[shading]="true"></dxo-load-panel>

		<!-- Fixable in position columns	-->
		<dxo-column-fixing [enabled]="true"></dxo-column-fixing>
		<ng-container *ngFor="let col of vo.list.availableColumns | toArray">
			<dxi-column
				*ngIf="col.field === 'avatar'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[alignment]="'left'"
				cellTemplate="avatarTemplate"></dxi-column>
			<dxi-column
				*ngIf="col.field === 'name'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[alignment]="'left'"
				cellTemplate="nameTemplate"></dxi-column>

			<dxi-column
				*ngIf="col.field !== 'avatar' && col.field !== 'name'"
				[dataField]="col.field"
				[caption]="col.alias"
				[groupIndex]="col.groupIdx"
				[fixed]="false"
				[allowResizing]="true"
				[allowHiding]="true"
				[allowGrouping]="true"
				[allowSearch]="col.isSearchable"
				[allowSorting]="true"
				[alignment]="'left'"></dxi-column>
		</ng-container>

		<dxi-column
			[fixed]="false"
			[allowResizing]="true"
			[allowHiding]="true"
			[allowGrouping]="true"
			[allowSearch]="false"
			[allowSorting]="true"
			[alignment]="'left'"
			[visibleIndex]="2"
			cellTemplate="deleteTemplate"></dxi-column>

		<div *dxTemplate="let cell of 'avatarTemplate'" class="avatar-template">
			<app-avatar
				[avatar]="cell.data['avatar']"
				[avatarSize]="'small'"
				[toolTipEnabled]="true"></app-avatar>
		</div>
		<div *dxTemplate="let cell of 'nameTemplate'" class="name-template">
			<span>{{ cell.data['name'] }}</span>
			<mat-icon
				*ngIf="cell.data['isGroup']"
				[svgIcon]="DcIcons.Group"></mat-icon>
		</div>
		<div *dxTemplate="let cell of 'deleteTemplate'" class="delete-template">
			<button
				id="{{ cmpId }}-{{ cell.rowIndex }}-remove-btn"
				mat-icon-button
				class="delete-template__close-btn"
				(click)="removeConsumer(cell.key)">
				<mat-icon [svgIcon]="DcIcons.Close"></mat-icon>
			</button>
		</div>

		<!-- Scrolling mode	-->
		<dxo-scrolling mode="virtual"></dxo-scrolling>

		<!-- Sorting	-->
		<dxo-sorting mode="multiple"></dxo-sorting>

		<!-- Pagination -->
		<dxo-paging [pageSize]="1000"></dxo-paging>
		<dxo-pager
			#pager
			[visible]="false"
			[showPageSizeSelector]="true"
			[showInfo]="true"
			[showNavigationButtons]="true"></dxo-pager>

		<!-- State persistence	-->
		<dxo-state-storing
			type="localStorage"
			[enabled]="false"
			[storageKey]="dxLocalStorageKey"></dxo-state-storing>
	</dx-data-grid>
</ng-container>
