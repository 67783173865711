import {
	ColumnType,
	IListAttrs,
	IListColumnConfig,
	IListOperations,
} from '@datachain/adb-common';
import { immerable, produce } from 'immer';
import { Map } from 'immutable';

import { ExpositionColumnConfigEntity } from './exposition-column-config.entity';

export enum ColumnConfigErrorKey {
	DuplicatedAlias = 'duplicate',
	NoActiveColumns = 'no-active-columns',
	ReferenceInAccessConfig = 'column-referenced-in-access',
}

export type ColumnConfigListError = {
	key: ColumnConfigErrorKey;
};

export interface IColumnConfigListExtra
	extends IListColumnConfig<ExpositionColumnConfigEntity> {
	isFixed?: boolean;
	isSelectable?: boolean;
	isSearchable?: boolean;
	isInGroup?: boolean;
	isVisible?: boolean;
	canBeHidden?: boolean;
	canBeReordered?: boolean;
	canBeChosen?: boolean;
	format?: string;
	visibleIndex?: number;
}

interface IColumnConfigListEntity
	extends IListAttrs<ExpositionColumnConfigEntity> {
	availableColumns: Map<string, IColumnConfigListExtra>;
	hashedColumns: Array<string>;
	hiddenColumns: Array<string>;
	hasDuplicatedAliases: boolean;
	errors: Map<ColumnConfigErrorKey, ColumnConfigListError>;
	allActive: boolean;
	allFilter: boolean;
	allIc: boolean;
	allHash: boolean;
	allHidden: boolean;
}

const entityDefaults: IColumnConfigListEntity = {
	elements: Map(),
	availableColumns: Map(),
	hashedColumns: [],
	hiddenColumns: [],
	hasDuplicatedAliases: false,
	errors: Map(),
	allActive: false,
	allFilter: false,
	allIc: false,
	allHash: false,
	allHidden: false,
};

export class ExpositionColumnConfigListEntity
	implements
		IColumnConfigListEntity,
		IListOperations<
			ExpositionColumnConfigListEntity,
			ExpositionColumnConfigEntity
		>
{
	private [immerable] = true;
	public elements = entityDefaults.elements;
	public availableColumns = entityDefaults.availableColumns;
	public hashedColumns = entityDefaults.hashedColumns;
	public hiddenColumns = entityDefaults.hiddenColumns;
	public hasDuplicatedAliases = entityDefaults.hasDuplicatedAliases;
	public allActive = entityDefaults.allActive;
	public allFilter = entityDefaults.allFilter;
	public allIc = entityDefaults.allIc;
	public allHash = entityDefaults.allHash;
	public allHidden = entityDefaults.allHidden;
	public errors = entityDefaults.errors;

	public static build(
		availableCols: Array<IColumnConfigListExtra>,
		withErrors?: Array<[ColumnConfigErrorKey, ColumnConfigListError]>
	): ExpositionColumnConfigListEntity {
		const inst = new ExpositionColumnConfigListEntity();
		const cols = availableCols.reduce((acc, curr) => {
			acc.push([curr.field, curr]);
			return acc;
		}, new Array<[string, IColumnConfigListExtra]>());
		inst.availableColumns = Map(cols);
		inst.errors = Map(withErrors);
		return inst.detectErrors().checkIfAllActive();
	}

	private constructor() {}

	public getAvailableColumns(): Map<
		string,
		IListColumnConfig<ExpositionColumnConfigEntity>
	> {
		return this.availableColumns;
	}

	public setElements(
		elems: Map<string, ExpositionColumnConfigEntity>,
		reset = false
	): ExpositionColumnConfigListEntity {
		if (reset) {
			const map = elems.map((e) => e.resetFilterField());
			const list = produce(this, (draft: ExpositionColumnConfigListEntity) => {
				draft.elements = map;
			});
			return list.detectErrors().checkIfAllActive();
		}
		const list = produce(this, (draft: ExpositionColumnConfigListEntity) => {
			draft.elements = elems;
		});
		return list.detectErrors().checkIfAllActive();
	}

	public getAllIds(): Array<string> {
		return this.elements.toArray().map(([id, el]) => id);
	}

	public updateElements(
		elems: Array<ExpositionColumnConfigEntity>
	): ExpositionColumnConfigListEntity {
		const tuples = elems.reduce((acc, curr) => {
			acc.push([curr.id, curr]);
			return acc;
		}, new Array<[string, ExpositionColumnConfigEntity]>());
		const updated = produce(this, (draft: ExpositionColumnConfigListEntity) => {
			draft.elements = Map(tuples);
		});
		return updated.detectErrors().checkIfAllActive();
	}

	public updateHash(
		elementIds: Array<string>,
		value: boolean
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		elementIds.forEach((id) => {
			const elem = this.elements.get(id);
			if (
				elem &&
				elem.isActive &&
				elem.columnType === ColumnType.Str &&
				this.hashedColumns.includes(id)
			) {
				const updatedElem = elem.updateHash(value);
				elems = elems.set(id, updatedElem);
			}
		});
		return this.setElements(elems);
	}

	public updateIsActive(
		elementIds: Array<string>,
		value: boolean
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		elementIds.forEach((id) => {
			const elem = this.elements.get(id);
			if (!elem) {
				return;
			}
			const updatedElem = elem.updateIsActive(value);
			elems = elems.set(id, updatedElem);
		});
		return this.setElements(elems, true);
	}

	public updateIsHidden(
		elementIds: Array<string>,
		value: boolean
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		elementIds.forEach((id) => {
			const elem = this.elements.get(id);
			if (elem && elem.isActive && !elem.isList) {
				const updatedElem = elem.updateIsHidden(value);
				elems = elems.set(id, updatedElem);
			}
		});
		return this.setElements(elems, true);
	}

	public updateIsCaseSensitive(
		elementIds: Array<string>,
		value: boolean
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		elementIds.forEach((id) => {
			const elem = this.elements.get(id);
			if (
				elem &&
				elem.isActive &&
				elem.columnType === ColumnType.Str &&
				!elem.isList
			) {
				const updatedElem = elem.updateIsCaseSensitive(value);
				elems = elems.set(id, updatedElem);
			}
		});
		return this.setElements(elems, true);
	}

	public updateIsFiltered(
		elementIds: Array<string>,
		value: boolean
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		elementIds.forEach((id) => {
			const elem = this.elements.get(id);
			if (elem && elem.isActive && !elem.isList) {
				const updatedElem = elem.updateIsFiltered(value);
				elems = elems.set(id, updatedElem);
			}
		});
		return this.setElements(elems, true);
	}

	public updateIsPrimaryKey(
		elementId: string,
		value: boolean
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		this.elements.forEach((elem) => {
			if (elem.id === elementId) {
				elems = elems.set(elem.id, elem.updateIsPrimaryKey(value));
			} else {
				elems = elems.set(elem.id, elem.updateIsPrimaryKey(false));
			}
		});
		return this.setElements(elems, true);
	}

	public updateAlias(
		elementId: string,
		value: string
	): ExpositionColumnConfigListEntity {
		const elem = this.elements.get(elementId);
		if (!elem) {
			return this;
		}
		const elems = this.elements.set(elementId, elem.updateAlias(value));
		return this.setElements(elems, true);
	}

	public updateDescription(
		elementId: string,
		value: string
	): ExpositionColumnConfigListEntity {
		let elems = this.elements;
		const elem = this.elements.get(elementId);
		if (!elem) {
			return this;
		}
		elems = elems.set(elementId, elem.updateDescription(value));
		return this.setElements(elems, false);
	}

	public cloneActiveColumns(
		params: Partial<ExpositionColumnConfigEntity>
	): ExpositionColumnConfigListEntity {
		const tuples = this.elements.reduce((acc, curr) => {
			if (curr.isActive) {
				acc.push([curr.id, curr.clone(params)]);
			}
			return acc;
		}, new Array<[string, ExpositionColumnConfigEntity]>());
		const inst = produce(this, (draft: ExpositionColumnConfigListEntity) => {
			draft.elements = Map(tuples);
		});
		return inst.checkIfAllActive().detectErrors();
	}

	public setHashedAndHiddenColumns(
		hashedColumns: Array<string>,
		hiddenColumns: Array<string>
	): ExpositionColumnConfigListEntity {
		return produce(this, (draft: ExpositionColumnConfigListEntity) => {
			draft.hashedColumns = hashedColumns;
			draft.hiddenColumns = hiddenColumns;
		});
	}

	public detectErrors(): ExpositionColumnConfigListEntity {
		const errors = new Array<[ColumnConfigErrorKey, ColumnConfigListError]>();
		const [hasDuplicates, elems] = this.tagDuplicates();
		if (hasDuplicates) {
			errors.push([
				ColumnConfigErrorKey.DuplicatedAlias,
				{
					key: ColumnConfigErrorKey.DuplicatedAlias,
				},
			]);
		}
		const activeCount = this.activeEntries(this.elements);
		if (activeCount === 0) {
			errors.push([
				ColumnConfigErrorKey.NoActiveColumns,
				{
					key: ColumnConfigErrorKey.NoActiveColumns,
				},
			]);
		}
		return produce(this, (draft: ExpositionColumnConfigListEntity) => {
			draft.hasDuplicatedAliases = hasDuplicates;
			draft.elements = hasDuplicates ? elems : this.elements;
			draft.errors = Map(errors);
		});
	}

	private tagDuplicates(): [
		boolean,
		Map<string, ExpositionColumnConfigEntity>
	] {
		let hasDuplicates = false;
		const els = new Array<ExpositionColumnConfigEntity>();
		const colsByAlias = this.generateColumnsByAliasForActiveCols(this.elements);
		[...colsByAlias.values()].forEach((l) => {
			if (l.length > 1) {
				const activeCount = this.activeEntries(l);
				hasDuplicates = hasDuplicates || activeCount > 1;
				l.forEach((ll) => els.push(ll.tagAsDuplicate(activeCount > 1)));
			} else {
				els.push(l[0]);
			}
		});
		const tuples = els.reduce((acc, curr) => {
			acc.push([curr.id, curr]);
			return acc;
		}, new Array<[string, ExpositionColumnConfigEntity]>());
		return [hasDuplicates, Map(tuples)];
		// return produce(this, (draft: ExpositionColumnConfigListEntity) => {
		// 	draft.hasDuplicatedAliases = hasDuplicates;
		// 	draft.elements = Map(tuples);
		// });
	}

	private generateColumnsByAliasForActiveCols(
		els: Map<string, ExpositionColumnConfigEntity>
	): Map<string, Array<ExpositionColumnConfigEntity>> {
		let all = Map<string, Array<ExpositionColumnConfigEntity>>();
		[...els.entries()].forEach(([, col]) => {
			if (all.has(col.alias)) {
				const existing = all.get(
					col.alias
				) as Array<ExpositionColumnConfigEntity>;
				existing.push(col);
				all = all.set(col.alias, existing);
			} else {
				all = all.set(col.alias, [col]);
			}
		});
		return all;
	}

	private checkIfAllActive(): ExpositionColumnConfigListEntity {
		const activeCount = this.activeEntries(this.elements);
		const activeNonListEntries = this.nonListEntries(this.elements);
		const activeStringNonListEntries = this.stringNonListEntries(this.elements);
		const stringHashableEntries = this.stringHashableEntries(this.elements);
		const maskableEntries = this.maskableEntries(this.elements);
		const filterableEntries = this.filterableEntries(this.elements);
		const filteredCount = this.filteredEntries(this.elements);
		const icCount = this.icEntries(this.elements);
		const hashCount = this.hashEntries(this.elements);
		const hiddenCount = this.maskedEntries(this.elements);

		return produce(this, (draft: ExpositionColumnConfigListEntity) => {
			draft.allActive = activeCount === this.elements.size;
			draft.allFilter =
				filterableEntries === 0 ? false : filteredCount === filterableEntries;
			draft.allIc =
				activeCount === 0 ? false : icCount === activeStringNonListEntries;
			draft.allHash =
				stringHashableEntries === 0
					? false
					: stringHashableEntries === hashCount;
			draft.allHidden =
				maskableEntries === 0 ? false : maskableEntries === hiddenCount;
		});
	}

	private maskableEntries(
		list: Map<string, ExpositionColumnConfigEntity>
	): number {
		return list.reduce<number>(
			(acc, curr) => (curr.isActive && !curr.isList ? acc + 1 : acc),
			0
		);
	}

	private filterableEntries(
		map: Map<string, ExpositionColumnConfigEntity>
	): number {
		return map.reduce<number>(
			(acc, curr) => (curr.isActive && !curr.isList ? acc + 1 : acc),
			0
		);
	}

	private stringNonListEntries(
		list: Map<string, ExpositionColumnConfigEntity>
	): number {
		return list.reduce<number>(
			(acc, curr) =>
				curr.isActive && !curr.isList && curr.columnType === ColumnType.Str
					? acc + 1
					: acc,
			0
		);
	}

	private stringHashableEntries(
		list: Map<string, ExpositionColumnConfigEntity>
	): number {
		return list.reduce<number>(
			(acc, curr) =>
				curr.isActive &&
				curr.columnType === ColumnType.Str &&
				this.hashedColumns.includes(curr.id)
					? acc + 1
					: acc,
			0
		);
	}

	private nonListEntries(
		list: Map<string, ExpositionColumnConfigEntity>
	): number {
		return list.reduce<number>(
			(acc, curr) => (curr.isActive && !curr.isList ? acc + 1 : acc),
			0
		);
	}

	private activeEntries(
		list:
			| Map<string, ExpositionColumnConfigEntity>
			| Array<ExpositionColumnConfigEntity>
	): number {
		if (Map.isMap(list)) {
			return list.reduce<number>(
				(acc, curr) => (curr.isActive ? acc + 1 : acc),
				0
			);
		}
		return list.reduce<number>(
			(acc, curr) => (curr.isActive ? acc + 1 : acc),
			0
		);
	}

	private maskedEntries(
		map: Map<string, ExpositionColumnConfigEntity>
	): number {
		return map.reduce<number>(
			(acc, curr) => (curr.isHidden ? acc + 1 : acc),
			0
		);
	}

	private filteredEntries(
		map: Map<string, ExpositionColumnConfigEntity>
	): number {
		return map.reduce<number>(
			(acc, curr) => (curr.isFiltered ? acc + 1 : acc),
			0
		);
	}

	private icEntries(map: Map<string, ExpositionColumnConfigEntity>): number {
		return map.reduce<number>(
			(acc, curr) => (curr.isCaseSensitive ? acc + 1 : acc),
			0
		);
	}

	private hashEntries(map: Map<string, ExpositionColumnConfigEntity>): number {
		return map.reduce<number>(
			(acc, curr) => (curr.isHashed ? acc + 1 : acc),
			0
		);
	}
}
