import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import { debounceTime, map, Observable, switchMap, take } from 'rxjs';

import { EndpointRequester } from '../requesters/async-validators/endpoint.requester';
import { ValidationErrorKeys } from '../ui/form/validation-erros-keys';

export class EndpointNameValidator {
	public static createValidator(
		expositionId: number | null,
		endPointRequester: EndpointRequester
	): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> =>
			control.valueChanges.pipe(
				debounceTime(750),
				take(1),
				switchMap((val) =>
					endPointRequester.validateEndpointName(val, expositionId)
				),
				map((response) =>
					response
						? null
						: {
								[ValidationErrorKeys.UnavailableToUse]: true,
						  }
				)
			);
	}
}
