import { Injectable } from '@angular/core';
import { AppTagItemEntity } from '@dc-common-core';
import { select, Store } from '@ngrx/store';
import { OrderedSet } from 'immutable';
import { map, Observable } from 'rxjs';

import { IAppRootState } from '../../core/store';
import { AccessConfigEntity } from '../components/exposition-access-config/exposition-access-config.entity';
import { ExpositionAccessListEntity } from '../components/exposition-access-list/exposition-access-list.entity';
import { ExpositionColumnConfigListEntity } from '../components/exposition-columns-config/exposition-column-config-list.entity';
import { ExpositionConsumerEntity } from '../components/exposition-consumers-list/exposition-consumer.entity';
import { ExpositionDatablockListEntity } from '../components/exposition-datablock-list/exposition-datablock-list.entity';
import { ExpositionEndpointMetadataEntity } from '../components/exposition-endpoint-metadata-config/exposition-endpoint-metadata.entity';
import { ExpositionHistoryListEntity } from '../components/exposition-history-list/exposition-history-list.entity';
import { ExpositionMetadataEntity } from '../components/exposition-internal-metadata-config/exposition-metadata.entity';
import { ExpositionItemEntity } from '../components/exposition-list/exposition-item.entity';
import { ExpositionListEntity } from '../components/exposition-list/exposition-list.entity';
import { ExpositionConsumerMappingEntity } from '../components/exposition-migration-stepper/exposition-consumer-mapping.entity';
import { MigrationReadyExpositionList } from '../components/exposition-migration-stepper/migration-ready-exposition-list.entity';
import { MigratedExpositionListEntity } from '../components/exposition-migrations-list/migrated-exposition-list.entity';
import { ExpositionEntity } from '../components/exposition-view/exposition.entity';
import { PublishedExpositionEntity } from '../components/published-exposition/published-exposition.entity';
import { initExpositionView } from './expositions.actions';
import {
	ExpositionsFeatureKey,
	ExpositionsState,
	PublicationAction,
} from './expositions.state';

interface ExpositionsRootState extends IAppRootState {
	[ExpositionsFeatureKey]: ExpositionsState;
}

@Injectable()
export class ExpositionsSelector {
	public constructor(private readonly store: Store<ExpositionsRootState>) {}

	private get state(): Observable<ExpositionsState> {
		return this.store.pipe(select((store) => store[ExpositionsFeatureKey]));
	}

	public getIsLoading$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('loading')));
	}

	public getActionInProgress$(): Observable<PublicationAction> {
		return this.state.pipe(map((state) => state.get('actionInProgress')));
	}

	public isSavingInProgress$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('isSavingExposition')));
	}

	public getIsLoadingDatablockColumns$(): Observable<boolean> {
		return this.state.pipe(
			map((state) => state.get('isLoadingDatablockColumns'))
		);
	}

	public getColumnConfigList$(): Observable<ExpositionColumnConfigListEntity> {
		return this.state.pipe(map((state) => state.get('stepper').columns));
	}

	public getDatablockList$(): Observable<ExpositionDatablockListEntity> {
		return this.state.pipe(map((state) => state.get('stepper').datablocks));
	}

	public getAvailableTags$(): Observable<OrderedSet<AppTagItemEntity>> {
		return this.state.pipe(map((state) => state.get('availableTags')));
	}

	public getAvailableConsumers$(): Observable<Array<ExpositionConsumerEntity>> {
		return this.state.pipe(map((state) => state.get('availableConsumers')));
	}

	public getDatablockLabel$(id: number): Observable<string> {
		return this.state.pipe(
			map(
				(state) => state.stepper.datablocks.elements.get(`${id}`)?.label ?? ''
			)
		);
	}

	public getExpositionView$(): Observable<ExpositionEntity> {
		return this.state.pipe(map((state) => state.get('inViewExposition')));
	}

	public getExpositionId$(): Observable<number> {
		return this.state.pipe(map((state) => state.get('inViewExposition').id));
	}

	public getColumns$(): Observable<ExpositionColumnConfigListEntity> {
		return this.state.pipe(
			map((state) => state.get('inViewExposition').columns)
		);
	}
	public getAccessList$(): Observable<ExpositionAccessListEntity> {
		return this.state.pipe(
			map((state) => state.get('inViewExposition').accessPoints)
		);
	}
	public getMetadata$(): Observable<ExpositionMetadataEntity> {
		return this.state.pipe(
			map((state) => state.get('inViewExposition').metadata)
		);
	}
	public getEndpointMetadata$(): Observable<ExpositionEndpointMetadataEntity> {
		return this.state.pipe(
			map((state) => state.get('inViewExposition').endpointMetadata)
		);
	}
	public getPublishedExposition$(): Observable<PublishedExpositionEntity> {
		return this.state.pipe(
			map((state) => state.get('inViewExposition').published)
		);
	}
	public getAccessConfig$(): Observable<AccessConfigEntity> {
		return this.state.pipe(
			map((state) => state.get('inViewExposition').accessPoints.currentConfig)
		);
	}

	public getExpositions$(): Observable<ExpositionListEntity> {
		return this.state.pipe(map((state) => state.get('expositions')));
	}

	public canCreateNewExposition$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('permissions').create));
	}

	public getCanDeleteExposition$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('permissions').delete));
	}

	public getCanEditExposition$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('permissions').edit));
	}

	public getExpositionsForMigration$(): Observable<MigrationReadyExpositionList> {
		return this.state.pipe(map((state) => state.get('v1Expositions')));
	}

	public getUnmappedConsumers$(): Observable<
		Array<ExpositionConsumerMappingEntity>
	> {
		return this.state.pipe(map((state) => state.get('v1Consumers')));
	}

	public getCurrentMappingRequestId$(): Observable<string> {
		return this.state.pipe(map((state) => state.get('mappingRequestId')));
	}

	public getMigratedExpositions$(): Observable<MigratedExpositionListEntity> {
		return this.state.pipe(map((state) => state.get('migrated')));
	}

	public getSelectedExpositions$(
		selected: Array<string>
	): Observable<Array<ExpositionItemEntity>> {
		return this.getExpositions$().pipe(
			map((list) =>
				selected.reduce<Array<ExpositionItemEntity>>((acc, curr) => {
					const maybe = list.elements.get(`${curr}`);
					if (maybe) {
						acc.push(maybe);
						return acc;
					}
					return acc;
				}, [])
			)
		);
	}

	public getHistory$(): Observable<ExpositionHistoryListEntity> {
		return this.state.pipe(map((state) => state.get('history')));
	}

	public getIsLoadingJobErrorLogs$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('isLoadingJobErrorLogs')));
	}
	public getIsLoadingMetadataInfo$(): Observable<boolean> {
		return this.state.pipe(map((state) => state.get('isLoadingMetadataInfo')));
	}

	public hasConfiguredOpenAccess$(accessId: string): Observable<boolean> {
		return this.getExpositionView$().pipe(
			map((initExpositionView) =>
				initExpositionView.accessPoints.hasConfiguredOpenAccess(accessId)
			)
		);
	}
}
