import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppTagItemEntity } from '@dc-common-core';
import { AppModalService, combineAll, UuidService } from '@dc-common-ui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OrderedSet } from 'immutable';
import {
	catchError,
	combineLatest,
	debounceTime,
	exhaustMap,
	filter,
	map,
	of,
	switchMap,
	tap,
	withLatestFrom,
} from 'rxjs';

import { NgCommService } from '../../ajs-ng-communication.service';
import { AjsScopeService } from '../../ajs-scope.service';
import { AjsStateService } from '../../ajs-state.service';
import { states } from '../../core/application.states';
import { isCurrentPage } from '../../core/utils/is-current-page';
import { DcViewRequester } from '../../requesters/core/dc-view.requester';
import { IJobTracking } from '../../requesters/core/job-tracking';
import { TagRequester } from '../../requesters/core/tag.requester';
import { ExpositionPermissionRequester } from '../../requesters/exposition/exposition-permission.requester';
import { IExpositionResponse } from '../../requesters/exposition/exposition.model';
import { ExpositionRequester } from '../../requesters/exposition/exposition.requester';
import { ShareableDatablockRequester } from '../../requesters/exposition/shareable-datablock.requester';
import { ExpositionAccessModalComponent } from '../components/exposition-access-modal/exposition-access-modal.component';
import {
	ExpositionAction,
	ExpositionActionConfirmationPopupComponent,
	IExpositionActionConfirmationModalData,
} from '../components/exposition-action-confirmation-popup/exposition-action-confirmation-popup.component';
import { ExpositionColumnsModalComponent } from '../components/exposition-columns-modal/exposition-columns-modal.component';
import { ExpositionEndpointMetadataPopupComponent } from '../components/exposition-endpoint-metadata-popup/exposition-endpoint-metadata-popup.component';
import { ExpositionMetadataPopupComponent } from '../components/exposition-metadata-popup/exposition-metadata-popup.component';
import { ExpositionMigrationStepperComponent } from '../components/exposition-migration-stepper/exposition-migration-stepper.component';
import { ExpositionStepperComponent } from '../components/exposition-stepper/exposition-stepper.component';
import { ExpositionEntity } from '../components/exposition-view/exposition.entity';
import { ExpositionsAdapter } from '../expositions.adapter';
import {
	closeCreateExpositionModal,
	closeEditExpositionApiModal,
	closeEditExpositionColumnsModal,
	confirmBeforePublishOnList,
	createExposition,
	createExpositionAccess,
	createExpositionFail,
	createExpositionSuccess,
	deleteExpositionAccessSuccess,
	ExpositionsActions,
	fetchAvailableConsumersSuccess,
	fetchAvailableExpositionsListFail,
	fetchAvailableExpositionsListSuccess,
	fetchAvailableTagsSuccess,
	fetchColumnConfigListSuccess,
	fetchDataBlockColumnsSuccess,
	fetchExpositionAccessSuccess,
	fetchExpositionColumnsSuccess,
	fetchExpositionDataBlockListSuccess,
	fetchExpositionEndpointMetadataSuccess,
	fetchExpositionMetadataSuccess,
	fetchExpositionSuccess,
	getAvailableConsumers,
	getColumnConfigList,
	getDataBlocksColumns,
	getExpositionColumns,
	goToCreateNewExpositionRoute,
	goToExpositionCreateAccessRoute,
	goToExpositionEditAccessRoute,
	goToExpositionEditColumnsRoute,
	goToExpositionsListRoute,
	initExpositionAccessCreation,
	initExpositionAccessModification,
	initExpositionStepper,
	initExpositionMetadataConfig,
	initExpositionsList,
	initExpositionView,
	navigateToExposition,
	openActivateConfirmationPopup,
	openClearDataConfirmationPopup,
	openCreateNewExpositionModal,
	openDeactivateConfirmationPopup,
	openDeleteAccessPopup,
	openDeleteConfirmationPopup,
	openEditExpositionApiModal,
	openEditExpositionColumnsModal,
	openEditExpositionEndpointMetadataPopup,
	openEditExpositionMetadataPopup,
	openPublishConfirmationPopup,
	openUpdateDataConfirmationPopup,
	openUpdateParamsConfirmationPopup,
	initPublishExposition,
	redirectToExpositionView,
	refreshDatablockColumns,
	refreshDatablocks,
	refreshExpositionColumns,
	refreshExpositionsList,
	refreshExpositionView,
	saveExpositionAccess,
	saveExpositionColumns,
	saveExpositionEndpointMetadata,
	saveExpositionMetadata,
	toggleAccessStatus,
	trackExpositionAction,
	updateExpositionData,
	updateExpositionParams,
	confirmBeforeDeleteExpoOnList,
	confirmationBeforeDeactivateOnList,
	confirmationBeforeClearDataOnList,
	confirmationBeforeActivateOnList,
	confirmUpdateExpositionDataOnList,
	bulkUpdateExpositionData,
	ExpositionJobPair,
	bulkPublishExposition,
	updateExpositionWithoutJobTracking,
	fetchExpositionViewOnEndpointDetailsTrigger,
	refreshAfterError,
	refreshDatablocksSuccess,
	refreshAfterErrorSuccess,
	fetchExpositionViewOnEndpointDetailsTriggerSuccess,
	goToMigrationsModalRoute,
	openInitNewExpositionMigrationModal,
	migrateSelectedExpositions,
	migrateSelectedExpositionsSuccess,
	migrateSelectedExpositionsFailed,
	initMigratedExpositionsList,
	fetchMigratedExpositionsListSuccess,
	fetchMigratedExpositionsListFail,
	fetchAvailableExpositionsForMigration,
	fetchAvailableExpositionsForMigrationSuccess,
	fetchAvailableExpositionsForMigrationFail,
	closeMigrateExpositionModal,
	refreshReadyToMigrateExpositionsList,
	retrievesConsumersToMap,
	retrievesConsumersToMapSuccess,
	retrievesConsumersToMapFail,
	refreshMigratedExpositionsList,
	goToExpositionHistoryRoute,
	getExpositionHistory,
	fetchExpositionHistorySuccess,
	refreshExpositionHistory,
	fetchExpositionOnHistoryNavigationSuccess,
	getJobErrorMessage,
	fetchJobErrorMessageSuccess,
	fetchJobErrorMessageFail,
	initProjectImportConsumersMapping,
	projectImportConsumersToMapSuccess,
	projectImportConsumersToMapFail,
	refreshProjectImportConsumersMapping,
	openCreateNewExpositionModalFromDatablock,
	goToExpositionFromDatablockListState,
	goToExpositionFromDatablockViewState,
} from './expositions.actions';
import { ExpositionsSelector } from './expositions.selector';

interface IExpositionJobSocketResponse {
	data: { status: string; client_id: string; job_id: string } | undefined;
}

@Injectable()
export class ExpositionsEffects {
	// ##########################################################
	// Navigation Effects
	// ##########################################################
	public navigateToExpositionView = createEffect(
		() =>
			this.actions$.pipe(
				ofType(navigateToExposition),
				tap((a) =>
					this.stateService.go(states.ExpositionView, {
						expositionId: `${a.expositionId}`,
					})
				)
			),
		{
			dispatch: false,
		}
	);

	public goToCreateNewExpositionRoute$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToCreateNewExpositionRoute),
				tap((action) => this.stateService.go(states.ExpositionCreate))
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionListRoute$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionsListRoute),
				tap((action) => this.stateService.go(states.ExpositionsList))
			),
		{
			dispatch: false,
		}
	);

	public goToMigrationsModalRoute$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToMigrationsModalRoute),
				tap((action) => this.stateService.go(states.ExpositionInitMigration))
			),
		{
			dispatch: false,
		}
	);

	public navigateToListAfterClosed$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(closeCreateExpositionModal),
				tap((action) => {
					this.modalService.closeActiveModal();
					if (isCurrentPage('datablocks', 'edit')) {
						this.stateService.go(states.DatablockEdit, {
							id: `${action.datablockId}`,
						});
						return;
					} else if (isCurrentPage('datablocks', 'expose')) {
						this.stateService.go(states.DatablockList);
						return;
					}
					this.stateService.go(states.ExpositionsList);
				})
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionView$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(redirectToExpositionView),
				tap((action) =>
					this.stateService.go(states.ExpositionView, {
						expositionId: `${action.expositionId}`,
					})
				)
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionEditColumnsRoute$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionEditColumnsRoute),
				tap((action) =>
					this.stateService.go(states.ExpositionEditColumns, {
						expositionId: `${action.expositionId}`,
					})
				)
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionEditAccessRoute$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionEditAccessRoute),
				tap((action) =>
					this.stateService.go(states.ExpositionEditApi, {
						expositionId: `${action.expositionId}`,
						accessId: `${action.accessId}`,
					})
				)
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionCreateAccessRoute$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionCreateAccessRoute),
				tap((action) =>
					this.stateService.go(states.ExpositionEditApi, {
						expositionId: `${action.expositionId}`,
						accessId: '-1',
					})
				)
			),
		{
			dispatch: false,
		}
	);

	public backToExpositionView$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(closeEditExpositionColumnsModal, closeEditExpositionApiModal),
				tap((action) => {
					this.modalService.closeActiveModal();
					this.stateService.go(states.ExpositionView, {
						expositionId: `${action.expositionId}`,
					});
				})
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionViewAfterCreate$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(createExpositionSuccess),
				tap((action) => {
					this.modalService.closeActiveModal();
					this.stateService.go(states.ExpositionView, {
						expositionId: `${action.expositionId}`,
					});
				})
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionMigrationsListOnSuccess$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(migrateSelectedExpositionsSuccess, closeMigrateExpositionModal),
				tap(() => {
					this.modalService.closeActiveModal();
					this.stateService.go(states.ExpositionListMigration);
				})
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionHistory$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionHistoryRoute),
				tap((action) => {
					this.stateService.go(states.ExpositionHistory, {
						expositionId: `${action.expositionId}`,
					});
				})
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionFromDatablockListState$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionFromDatablockListState),
				tap((action) =>
					this.stateService.go(states.ExpositionFromDatablockList, {
						datablockId: `${action.id}`,
						datablockLabel: action.label,
					})
				)
			),
		{
			dispatch: false,
		}
	);

	public goToExpositionFromDatablockViewState$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(goToExpositionFromDatablockViewState),
				tap((action) =>
					this.stateService.go(states.ExpositionFromDatablockView, {
						id: `${action.id}`,
						datablockLabel: action.label,
					})
				)
			),
		{
			dispatch: false,
		}
	);

	// ##########################################################
	// Business Effects
	// ##########################################################

	public initExpositionsList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExpositionsList, refreshExpositionsList),
			switchMap((action) =>
				combineAll({
					datablockId: action.datablockId,
					list: this.expositionRequester.getAllExpositions(),
					hasCreateExpositionPermission:
						this.expPermissionRequester.haveGlobalPermission(
							'exposition-create'
						),
				})
			),
			map(({ datablockId, list, hasCreateExpositionPermission }) =>
				fetchAvailableExpositionsListSuccess({
					list: this.expositionsAdapter.generateList(list, datablockId),
					canCreate: hasCreateExpositionPermission,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExpositions.error:`
				);
				console.error(error);
				this.store.dispatch(
					fetchAvailableExpositionsListFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public initMigratedExpositionsList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initMigratedExpositionsList, refreshMigratedExpositionsList),
			switchMap((action) => {
				if (action.type === ExpositionsActions.RefreshMigratedExpositionsList) {
					return this.expositionRequester.getMigratedExpositionsList({
						noCache: true,
					});
				}
				return this.expositionRequester.getMigratedExpositionsList();
			}),
			map((list) =>
				fetchMigratedExpositionsListSuccess({
					migrated:
						this.expositionsAdapter.generateMigratedExpositionList(list),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExpositions.error:`
				);
				console.error(error);
				this.store.dispatch(
					fetchMigratedExpositionsListFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchAvailableExpositionsForMigration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				fetchAvailableExpositionsForMigration,
				refreshReadyToMigrateExpositionsList
			),
			switchMap((action) => {
				if (
					action.type ===
					ExpositionsActions.RefreshReadyToMigrateExpositionsList
				) {
					return this.expositionRequester.getExpositionsForMigrationList({
						noCache: true,
					});
				}
				return this.expositionRequester.getExpositionsForMigrationList();
			}),
			map((list) =>
				fetchAvailableExpositionsForMigrationSuccess({
					eligible:
						this.expositionsAdapter.generateReadyForMigrationExpositionList(
							list
						),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExpositions.error:`
				);
				console.error(error);
				this.store.dispatch(
					fetchAvailableExpositionsForMigrationFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchConsumersRelativeToExposedDatablocks$ = createEffect(() =>
		this.actions$.pipe(
			ofType(retrievesConsumersToMap),
			switchMap((action) =>
				combineAll({
					unmappedConsumers:
						this.expositionRequester.getConsumersForExposedDatablocks(
							action.datablockIds
						),
					availableConsumers: this.dcViewRequester.getViewMembers(),
				})
			),
			map((resp) => {
				const res = this.expositionsAdapter.generatePreviousExpositionConsumers(
					resp.unmappedConsumers
				);
				return retrievesConsumersToMapSuccess({
					requestId: res.requestId,
					unmappedConsumers: res.consumers,
					availableConsumers: this.expositionsAdapter.generateConsumers(
						resp.availableConsumers
					),
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExpositions.error:`
				);
				console.error(error);
				this.store.dispatch(
					retrievesConsumersToMapFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public fetchConsumersForImportedProject$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				initProjectImportConsumersMapping,
				refreshProjectImportConsumersMapping
			),
			switchMap(() => this.dcViewRequester.getViewMembersOnProjectImport()),
			map((resp) =>
				projectImportConsumersToMapSuccess({
					availableConsumers: this.expositionsAdapter.generateConsumers(resp),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExpositions.error:`
				);
				console.error(error);
				this.store.dispatch(
					projectImportConsumersToMapFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public createNewExpositionModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openCreateNewExpositionModal),
				map((action) =>
					this.modalService.openModal(
						ExpositionStepperComponent,
						{
							title: $localize`:i18n=@@expositions.create.title:`,
							subTitle: $localize`:i18n=@@expositions.create.subTitle:`,
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public createExpositionFromDatablockModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openCreateNewExpositionModalFromDatablock),
				map((action) =>
					this.modalService.openModal<{
						datablockId: number;
						datablockLabel: string;
					}>(
						ExpositionStepperComponent,
						{
							title: $localize`:i18n=@@expositions.create.title:`,
							subTitle: $localize`:i18n=@@expositions.create.subTitle:`,
							datablockId: action.id,
							datablockLabel: action.label,
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public initNewExpositionMigrationModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openInitNewExpositionMigrationModal),
				map((action) =>
					this.modalService.openModal(
						ExpositionMigrationStepperComponent,
						{
							title: $localize`:i18n=@@exposition.migration.stepper.header.title:`,
							subTitle: $localize`:i18n=@@exposition.migration.stepper.header.subtitle:`,
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public getDatablockColumns$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getDataBlocksColumns),
			switchMap((action) =>
				combineLatest([
					of(action.datablockId),
					this.expositionDatablockRequester.getDatablockColumns(
						action.datablockId,
						true
					),
				])
			),
			map(([datablockId, response]) =>
				fetchDataBlockColumnsSuccess({
					datablockId,
					columns: this.expositionsAdapter.generateDatablockColumns(response),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getDatablockColumns.error:`
				);
				return caught;
			})
		)
	);

	public getDatablockList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExpositionStepper),
			switchMap((action) =>
				combineAll({
					datablockId: action.datablockId,
					eligibleDatablocks:
						this.expositionDatablockRequester.getShareableDatablocks(true),
					list: this.expositionRequester.getAllExpositions(),
				})
			),
			map(({ datablockId, eligibleDatablocks, list }) =>
				fetchExpositionDataBlockListSuccess({
					datablocks:
						this.expositionsAdapter.buildDatablockList(eligibleDatablocks),
					availableExpositions: this.expositionsAdapter.generateList(
						list,
						datablockId
					),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getDatablocks.error:`
				);
				return caught;
			})
		)
	);

	public getSelectedDatablockColumns$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getColumnConfigList, refreshDatablockColumns),
			switchMap((action) =>
				this.expositionDatablockRequester.getDatablockColumns(
					action.datablockId,
					action.type === ExpositionsActions.GetExpositionColumnConfigList
				)
			),
			map((response) =>
				fetchColumnConfigListSuccess({
					list: this.expositionsAdapter.buildColumnConfigList(response),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getDatablockColumns.error:`
				);
				return caught;
			})
		)
	);

	public tags$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExpositionStepper, initExpositionMetadataConfig),
			switchMap(() => this.tagRequester.getAvailableTags()),
			map((res) => {
				// FIXME: use tag adapter when imports(to be imported from dc-common) are fixed
				const availableTags = res
					.map<AppTagItemEntity>((t) =>
						AppTagItemEntity.build({
							id: t.id,
							label: t.code,
							color: t.color,
						})
					)
					.sort((t1, t2) =>
						t1.label.toLowerCase().localeCompare(t2.label.toLowerCase())
					);
				return OrderedSet(availableTags);
			}),
			map((tags) =>
				fetchAvailableTagsSuccess({
					tags,
				})
			)
		)
	);

	public openEditExpositionColumnsModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openEditExpositionColumnsModal),
				map((action) =>
					this.modalService.openModal<{ expositionId: number }>(
						ExpositionColumnsModalComponent,
						{
							title: $localize`:i18n=@@expositions.edit.columns.title:`,
							subTitle: '',
							expositionId: action.expositionId,
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public openEditExpositionApiModal$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openEditExpositionApiModal),
				map((action) =>
					this.modalService.openModal<{
						expositionId: number;
						accessId: string;
					}>(
						ExpositionAccessModalComponent,
						{
							title: $localize`:i18n=@@expositions.edit.api.title:`,
							subTitle: '',
							expositionId: action.expositionId,
							accessId: action.accessId,
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public getAvailableConsumers$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				getAvailableConsumers,
				initExpositionAccessCreation,
				initExpositionAccessModification
			),
			switchMap(() => this.dcViewRequester.getViewMembers()),
			map((members) =>
				fetchAvailableConsumersSuccess({
					consumers: this.expositionsAdapter.generateConsumers(members),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getViewMembers.error:`
				);
				return caught;
			})
		)
	);

	public createExposition$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createExposition),
			withLatestFrom(this.selector.isSavingInProgress$()),
			exhaustMap(([action, inProgress]) =>
				this.expositionRequester.createExposition(action.payload)
			),
			tap(() => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.create.success:`
				);
			}),
			map((exposition) =>
				createExpositionSuccess({
					expositionId: exposition.id,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.create.error:`
				);
				console.error(error);
				this.store.dispatch(
					createExpositionFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public migrateSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(migrateSelectedExpositions),
			withLatestFrom(
				this.selector.isSavingInProgress$(),
				this.selector.getCurrentMappingRequestId$()
			),
			exhaustMap(([action, inProgress, requestId]) =>
				this.expositionRequester.mapConsumers(requestId, action.mapping)
			),
			tap(() => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.migration.success:`
				);
			}),
			map(() => migrateSelectedExpositionsSuccess()),
			catchError((error: HttpErrorResponse, caught) => {
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.migration.error:`
				);
				console.error(error);
				this.store.dispatch(
					migrateSelectedExpositionsFailed({
						error,
					})
				);
				return caught;
			})
		)
	);

	public getExposition$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExpositionView, refreshExpositionView),
			switchMap((action) =>
				combineAll({
					targetExposition: this.expositionRequester.getExposition(
						action.expositionId
					),
					list: this.expositionRequester.getAllExpositions(),
					permissions:
						this.expPermissionRequester.checkExpositionViewPermissions(
							action.expositionId
						),
				})
			),
			map(({ targetExposition, list, permissions }) =>
				fetchExpositionSuccess({
					exposition:
						this.expositionsAdapter.generateExposition(targetExposition),
					availableExpositions: this.expositionsAdapter.generateList(list),
					permissions,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(refreshAfterError());
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExposition.error:`
				);
				return caught;
			})
		)
	);

	public getExpositionAfterError$ = createEffect(() =>
		this.actions$.pipe(
			ofType(refreshAfterError),
			tap(() =>
				this.ngCommService.notifyOnWarning(
					$localize`:i18n=@@expositions.unknown.error:`
				)
			),
			withLatestFrom(this.selector.getExpositionId$()),
			switchMap(([, inViewExpositionId]) =>
				this.expositionRequester.getExposition(inViewExpositionId)
			),
			map((response) =>
				refreshAfterErrorSuccess({
					exposition: this.expositionsAdapter.generateExposition(response),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExposition.error:`
				);
				return caught;
			})
		)
	);

	public getExpositionViewForExposition$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fetchExpositionViewOnEndpointDetailsTrigger),
			withLatestFrom(this.selector.getExpositionView$()),
			switchMap(([action, expositionView]) => {
				if (expositionView.id === action.expositionId) {
					return combineAll({
						cached: true,
						view: of(expositionView),
					});
				}
				return combineAll({
					cached: false,
					view: this.expositionRequester.getExposition(action.expositionId),
				});
			}),
			map((res) => {
				if (res.cached) {
					return fetchExpositionViewOnEndpointDetailsTriggerSuccess({
						exposition: res.view as ExpositionEntity,
					});
				}
				return fetchExpositionViewOnEndpointDetailsTriggerSuccess({
					exposition: this.expositionsAdapter.generateExposition(
						res.view as IExpositionResponse
					),
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExposition.error:`
				);
				return caught;
			})
		)
	);

	public getExpositionForExpositionHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getExpositionHistory),
			switchMap((action) =>
				this.expositionRequester.getExposition(action.expositionId)
			),
			map((targetExposition) =>
				fetchExpositionOnHistoryNavigationSuccess({
					exposition:
						this.expositionsAdapter.generateExposition(targetExposition),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExposition.error:`
				);
				return caught;
			})
		)
	);

	public publishExposition$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initPublishExposition, bulkPublishExposition),
			exhaustMap((action) => {
				const clientUuid = this.uuidService.uuidV4();
				if (action.type === ExpositionsActions.BulkPublishExposition) {
					return combineAll({
						clientId: clientUuid,
						bulkAction: true,
						expJobPair: combineLatest(
							action.expositionId.map((expId) =>
								combineLatest([
									of(expId),
									this.expositionRequester.publishExposition(clientUuid, expId),
								])
							)
						),
					});
				}
				return combineAll({
					clientId: clientUuid,
					bulkAction: false,
					expJobPair: combineLatest([
						of(action.expositionId),
						this.expositionRequester.publishExposition(
							clientUuid,
							action.expositionId
						),
					]),
				});
			}),
			tap(() =>
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.publish.inProgress:`
				)
			),
			map((payload) => {
				if (payload.bulkAction) {
					const tmp = payload.expJobPair as Array<[number, IJobTracking]>;
					return trackExpositionAction({
						isBulkAction: true,
						clientId: payload.clientId,
						expJobIdPair: tmp.map(([e, j]) => [
							e,
							j.job_id,
						]) as Array<ExpositionJobPair>,
						action: 'publish',
					});
				} else {
					const [e, j] = payload.expJobPair as [number, IJobTracking];
					return trackExpositionAction({
						isBulkAction: false,
						clientId: payload.clientId,
						expJobIdPair: [e, j.job_id] as ExpositionJobPair,
						action: 'publish',
					});
				}
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(refreshAfterError());
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public updateExpositionData$ = createEffect(() =>
		this.actions$.pipe(
			ofType(updateExpositionData, bulkUpdateExpositionData),
			exhaustMap((action) => {
				const clientUuid = this.uuidService.uuidV4();
				if (action.type === ExpositionsActions.BulkUpdateExpositionData) {
					return combineAll({
						bulkAction: true,
						clientId: clientUuid,
						expJobPair: combineLatest(
							action.expositionId.map((expId) =>
								combineLatest([
									of(expId),
									this.expositionRequester.updateExpositionData(
										clientUuid,
										expId
									),
								])
							)
						),
					});
				}
				return combineAll({
					bulkAction: false,
					clientId: clientUuid,
					expJobPair: combineLatest([
						of(action.expositionId),
						this.expositionRequester.updateExpositionData(
							clientUuid,
							action.expositionId
						),
					]),
				});
			}),
			tap(() =>
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.updateData.inProgress:`
				)
			),
			map((payload) => {
				if (payload.bulkAction) {
					const tmp = payload.expJobPair as Array<[number, IJobTracking]>;
					return trackExpositionAction({
						isBulkAction: true,
						clientId: payload.clientId,
						expJobIdPair: tmp.map(([e, j]) => [
							e,
							j.job_id,
						]) as Array<ExpositionJobPair>,
						action: 'update-data',
					});
				} else {
					const [e, j] = payload.expJobPair as [number, IJobTracking];
					return trackExpositionAction({
						isBulkAction: false,
						clientId: payload.clientId,
						expJobIdPair: [e, j.job_id] as ExpositionJobPair,
						action: 'update-data',
					});
				}
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(refreshAfterError());
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public updateExpositionParams$ = createEffect(() =>
		this.actions$.pipe(
			ofType(updateExpositionParams),
			exhaustMap((action) => {
				const clientUuid = this.uuidService.uuidV4();
				return combineAll({
					clientId: clientUuid,
					expositionId: action.expositionId,
					job: this.expositionRequester.updateExpositionParams(
						clientUuid,
						action.expositionId,
						action.updateMetadata,
						action.updateColumns,
						action.updateAccess
					),
				});
			}),
			map((payload) => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.update.inProgress:`
				);
				return trackExpositionAction({
					isBulkAction: false,
					clientId: payload.clientId,
					expJobIdPair: [payload.expositionId, payload.job.job_id],
					action: 'update',
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public updateExpositionWithoutJobTracking$ = createEffect(() =>
		this.actions$.pipe(
			ofType(updateExpositionWithoutJobTracking),
			exhaustMap((action) => {
				const clientUuid = this.uuidService.uuidV4();
				return combineAll({
					clientId: clientUuid,
					expositionId: action.expositionId,
					job: this.expositionRequester.updateExpositionParams(
						clientUuid,
						action.expositionId,
						action.updateMetadata,
						false,
						action.updateAccess
					),
				});
			}),
			map((payload) => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.update.success:`
				);
				return refreshExpositionView({
					expositionId: payload.expositionId,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public getExpositionColumns$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getExpositionColumns, refreshExpositionColumns),
			withLatestFrom(
				this.selector.getExpositionId$(),
				this.selector.getColumns$()
			),
			filter(
				([action, expositionId, columns]) =>
					action.type === ExpositionsActions.RefreshExpositionColumns ||
					action.expositionId !== expositionId ||
					!columns.elements.size
			),
			switchMap(([action]) =>
				combineAll({
					columns: this.expositionRequester.getExpositionColumns(
						action.expositionId
					),
					permissions:
						this.expPermissionRequester.checkExpositionViewPermissions(
							action.expositionId
						),
				})
			),
			map((response) =>
				fetchExpositionColumnsSuccess({
					columns: this.expositionsAdapter.generateExpositionColumns(
						response.columns
					),
					permissions: response.permissions,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getExpositionColumns.error:`
				);
				return caught;
			})
		)
	);

	public updateExpositionColumns$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(saveExpositionColumns),
				switchMap((action) =>
					combineLatest([
						of(action.expositionId),
						this.expositionRequester.updateExpositionColumns(
							action.expositionId,
							action.columns.elements.toArray().map(([, column]) => column)
						),
					])
				),
				tap(([expositionId]) => {
					this.modalService.closeActiveModal();
					this.ngCommService.notifyOnSuccess(
						$localize`:i18n=@@expositions.save.success:`
					);
					this.stateService.go(states.ExpositionView, {
						expositionId: `${expositionId}`,
					});
				}),
				catchError((error: HttpErrorResponse, caught) => {
					console.error(error);
					this.ngCommService.notifyOnError(
						$localize`:i18n=@@expositions.save.columns.error:`
					);
					return caught;
				})
			),
		{
			dispatch: false,
		}
	);

	public updateExpositionMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveExpositionMetadata),
			switchMap((action) =>
				this.expositionRequester.updateExpositionMetadata(
					action.expositionId,
					action.metadata
				)
			),
			map((response) => {
				this.modalService.closeActiveModal();
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.save.success:`
				);
				return fetchExpositionMetadataSuccess({
					metadata:
						this.expositionsAdapter.buildExpositionMetadataEntity(response),
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.save.metadata.error:`
				);
				return caught;
			})
		)
	);

	public updateExpositionEndpointMetadata$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveExpositionEndpointMetadata),
			switchMap((action) =>
				this.expositionRequester.updateExpositionEndpointMetadata(
					action.expositionId,
					action.metadata
				)
			),
			map((response) => {
				this.modalService.closeActiveModal();
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.save.success:`
				);
				return fetchExpositionEndpointMetadataSuccess({
					metadata:
						this.expositionsAdapter.buildExpositionEndpointMetadata(response),
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.save.endpointMetadata.error:`
				);
				return caught;
			})
		)
	);

	public getExpositionAccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExpositionAccessModification),
			switchMap((action) =>
				combineAll({
					targetExposition: this.expositionRequester.getExposition(
						action.expositionId
					),
					columns: this.expositionRequester.getExpositionColumns(
						action.expositionId
					),
					access: this.expositionRequester.getExpositionAccess(
						action.expositionId,
						action.accessId
					),
					permissions:
						this.expPermissionRequester.checkExpositionViewPermissions(
							action.expositionId
						),
				})
			),
			map((result) =>
				fetchExpositionAccessSuccess({
					access: this.expositionsAdapter.generateExistingAccessEntity(
						result.access,
						result.columns
					),
					permissions: result.permissions,
					exposition: this.expositionsAdapter.generateExposition(
						result.targetExposition
					),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getAccess.error:`
				);
				return caught;
			})
		)
	);

	public initExpositionAccessCreation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(initExpositionAccessCreation),
			switchMap((action) =>
				combineAll({
					targetExposition: this.expositionRequester.getExposition(
						action.expositionId
					),
					columns: this.expositionRequester.getExpositionColumns(
						action.expositionId
					),
					permissions:
						this.expPermissionRequester.checkExpositionViewPermissions(
							action.expositionId
						),
				})
			),
			map((result) =>
				fetchExpositionAccessSuccess({
					access: this.expositionsAdapter.generateNewAccessEntity(
						result.columns
					),
					exposition: this.expositionsAdapter.generateExposition(
						result.targetExposition
					),
					permissions: result.permissions,
				})
			)
		)
	);

	public updateExpositionAccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(saveExpositionAccess),
			switchMap((action) =>
				combineLatest([
					of(action.expositionId),
					this.expositionRequester.updateExpositionAccess(
						action.expositionId,
						action.access
					),
				])
			),
			map(([expositionId]) => {
				this.modalService.closeActiveModal();
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.save.success:`
				);
				return closeEditExpositionApiModal({
					expositionId,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.save.access.error:`
				);
				return caught;
			})
		)
	);

	public createExpositionAccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createExpositionAccess),
			switchMap((action) =>
				combineLatest([
					this.expositionRequester.createExpositionAccess(
						action.expositionId,
						action.access
					),
					of(action.expositionId),
				])
			),
			map(([, expositionId]) =>
				closeEditExpositionApiModal({
					expositionId,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.save.access.error:`
				);
				return caught;
			})
		)
	);

	public toggleAccessStatus = createEffect(() =>
		this.actions$.pipe(
			ofType(toggleAccessStatus),
			switchMap((action) =>
				combineLatest([
					of(action),
					this.expositionRequester.getExpositionAccess(
						action.expositionId,
						action.accessId
					),
				])
			),
			switchMap(([action, access]) =>
				combineLatest([
					of(action),
					this.expositionRequester.updateExpositionAccess(
						action.expositionId,
						this.expositionsAdapter.generateExpositionAccessEntity({
							...access,
							active: !access.active,
						})
					),
				])
			),
			map(([action]) =>
				refreshExpositionView({
					expositionId: action.expositionId,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.toggleAccessStatus.error:`
				);
				return caught;
			})
		)
	);

	public refreshDatablockList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(refreshDatablocks),
			switchMap(() =>
				this.expositionDatablockRequester.getShareableDatablocks(false)
			),
			map((response) =>
				refreshDatablocksSuccess({
					datablocks: this.expositionsAdapter.buildDatablockList(response),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.getDatablocks.error:`
				);
				return caught;
			})
		)
	);

	public getExpositionHistory$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getExpositionHistory, refreshExpositionHistory),
			switchMap((a) =>
				this.expositionRequester.getExpositionHistory(a.expositionId)
			),
			map((response) =>
				fetchExpositionHistorySuccess({
					history:
						this.expositionsAdapter.generateExpositionHistoryList(response),
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.history.get.error:`
				);
				return caught;
			})
		)
	);

	public getJobErrorMessage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(getJobErrorMessage),
			switchMap((action) =>
				combineLatest([
					of(action.historyId),
					this.expositionRequester.getJobErrorMessage(
						action.expositionId,
						action.historyId
					),
				])
			),
			map(([id, response]) =>
				fetchJobErrorMessageSuccess({
					id,
					message: response,
				})
			),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(
					fetchJobErrorMessageFail({
						error,
					})
				);
				return caught;
			})
		)
	);

	public listenToWS$ = createEffect(() =>
		this.actions$.pipe(
			ofType(trackExpositionAction),
			switchMap((action) =>
				combineAll({
					action,
					stompResponse:
						this.ajsScopeService.listenOnRootScope<IExpositionJobSocketResponse>(
							'globalRunningJobs'
						),
				})
			),
			filter(({ action, stompResponse }) => {
				if (action.isBulkAction) {
					const pairs = action.expJobIdPair as Array<ExpositionJobPair>;
					return (
						stompResponse?.data?.client_id === action.clientId &&
						pairs
							.map(([, jobId]) => jobId)
							.includes(stompResponse?.data?.job_id) &&
						stompResponse?.data?.status.toLocaleLowerCase() !== 'running' &&
						stompResponse?.data?.status.toLocaleLowerCase() !== 'waiting'
					);
				}
				const [, jobId] = action.expJobIdPair as ExpositionJobPair;
				return (
					stompResponse?.data?.client_id === action.clientId &&
					jobId === stompResponse?.data?.job_id &&
					stompResponse?.data?.status.toLocaleLowerCase() !== 'running' &&
					stompResponse?.data?.status.toLocaleLowerCase() !== 'waiting'
				);
			}),
			debounceTime(1000),
			map(({ action, stompResponse }) => {
				if (
					stompResponse.data !== undefined &&
					stompResponse?.data?.status.toLocaleLowerCase() === 'error' &&
					action.action === 'publish'
				) {
					this.ngCommService.notifyOnError(
						$localize`:i18n=@@expositions.publish.error:`
					);
					const jobId = stompResponse.data.job_id;
					if (action.isBulkAction) {
						const pairs = action.expJobIdPair as Array<ExpositionJobPair>;
						const [expId] = pairs.filter(([e, j]) => j === jobId)[0];
						throw new Error('publication bulk action error');
					}
					const [expId] = action.expJobIdPair as ExpositionJobPair;
					throw new Error('publication  single action error');
				} else if (
					stompResponse.data !== undefined &&
					stompResponse?.data?.status.toLocaleLowerCase() !== 'error'
				) {
					const jobId = stompResponse.data.job_id;
					if (isCurrentPage('exposition', 'view') && !action.isBulkAction) {
						const [expositionForJobId] =
							action.expJobIdPair as ExpositionJobPair;
						return refreshExpositionView({
							expositionId: expositionForJobId,
						});
					}
					const pairs = action.expJobIdPair as Array<ExpositionJobPair>;
					const [expositionForJobId] = pairs.filter(([, j]) => j === jobId)[0];
					return refreshExpositionsList({
						expositionId: expositionForJobId,
					});
				}
				throw new Error('unknown publish action error');
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.store.dispatch(refreshAfterError());
				return caught;
			})
		)
	);

	// ##########################################################
	// Edit in popup effects
	// ##########################################################

	public openEditExpositionMetadataPopup$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openEditExpositionMetadataPopup),
				map((action) =>
					this.modalService.openPopup<{ expositionId: number }>(
						ExpositionMetadataPopupComponent,
						{
							title: $localize`:i18n=@@expositions.edit.metadata.title:`,
							subTitle: '',
							expositionId: action.expositionId,
							width: '35%',
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	public openEditExpositionEndpointMetadataPopup$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(openEditExpositionEndpointMetadataPopup),
				map((action) =>
					this.modalService.openPopup<{ expositionId: number }>(
						ExpositionEndpointMetadataPopupComponent,
						{
							title: $localize`:i18n=@@expositions.edit.endpointMetadata.title:`,
							subTitle: '',
							expositionId: action.expositionId,
							width: '35%',
						},
						{
							disableClose: true,
						}
					)
				)
			),
		{
			dispatch: false,
		}
	);

	// ##########################################################
	// Confirmation popup effects
	// ##########################################################

	public openPublishConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openPublishConfirmationPopup, confirmBeforePublishOnList),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.publish.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.Publish,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			map(([action]) => {
				if (action.type === ExpositionsActions.ConfirmBeforePublishOnList) {
					return bulkPublishExposition({
						expositionId: action.expositionId as Array<number>,
					});
				}
				return initPublishExposition({
					expositionId: action.expositionId,
				});
			})
		)
	);

	public openUpdateDataConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				openUpdateDataConfirmationPopup,
				confirmUpdateExpositionDataOnList
			),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.updateData.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.UpdateData,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			map(([action]) => {
				if (
					action.type === ExpositionsActions.ConfirmUpdateExpositionDataOnList
				) {
					return bulkUpdateExpositionData({
						expositionId: action.expositionId as Array<number>,
					});
				}
				return updateExpositionData({
					expositionId: action.expositionId,
				});
			})
		)
	);

	public openUpdateConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openUpdateParamsConfirmationPopup),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.update.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.Update,
							hasActiveAccess: action.hasActiveAccess,
							hasColumnsChanged: false,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			map(([action, modalResult]) => {
				if (modalResult.updateColumns) {
					return updateExpositionParams({
						expositionId: action.expositionId,
						updateMetadata: modalResult.updateMetadata,
						updateColumns: modalResult.updateColumns,
						updateAccess: modalResult.updateAccess,
					});
				}
				return updateExpositionWithoutJobTracking({
					expositionId: action.expositionId,
					updateMetadata: modalResult.updateMetadata,
					updateAccess: modalResult.updateAccess,
				});
			})
		)
	);

	public openClearDataConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openClearDataConfirmationPopup, confirmationBeforeClearDataOnList),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.clear.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.ClearData,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			switchMap(([action]) => {
				if (Array.isArray(action.expositionId)) {
					return combineAll({
						action: of(action),
						res: combineLatest(
							action.expositionId.map((expId) =>
								this.expositionRequester.clearExpositionData(expId as number)
							)
						),
					});
				}
				return combineAll({
					action: of(action),
					res: this.expositionRequester.clearExpositionData(
						action.expositionId
					),
				});
			}),
			map(({ action }) => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.clearData.success:`
				);
				if (action.type === ExpositionsActions.ConfirmationBeforeClearData) {
					return refreshExpositionsList({
						expositionId: undefined,
					});
				}
				return refreshExpositionView({
					expositionId: action.expositionId,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public openActivateConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openActivateConfirmationPopup, confirmationBeforeActivateOnList),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.activate.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.Activate,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			switchMap(([action]) => {
				if (Array.isArray(action.expositionId)) {
					return combineAll({
						action: of(action),
						res: combineLatest(
							action.expositionId.map((expId) =>
								this.expositionRequester.activateExposition(expId as number)
							)
						),
					});
				}
				return combineAll({
					action: of(action),
					result: this.expositionRequester.activateExposition(
						action.expositionId as number
					),
				});
			}),
			map(({ action }) => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.activate.success:`
				);
				if (action.type === ExpositionsActions.ConfirmationBeforeActivate) {
					return refreshExpositionsList({
						expositionId: undefined,
					});
				}
				return refreshExpositionView({
					expositionId: action.expositionId,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public openDeactivateConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				openDeactivateConfirmationPopup,
				confirmationBeforeDeactivateOnList
			),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.deactivate.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.Deactivate,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			switchMap(([action]) => {
				if (Array.isArray(action.expositionId)) {
					return combineAll({
						action: of(action),
						res: combineLatest(
							action.expositionId.map((expId) =>
								this.expositionRequester.deactivateExposition(expId as number)
							)
						),
					});
				}
				return combineAll({
					action: of(action),
					result: this.expositionRequester.deactivateExposition(
						action.expositionId
					),
				});
			}),
			map(({ action }) => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.deactivate.success:`
				);
				if (action.type === ExpositionsActions.ConfirmationBeforeDeactivate) {
					return refreshExpositionsList({
						expositionId: undefined,
					});
				}
				return refreshExpositionView({
					expositionId: action.expositionId,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.launchAction.error:`
				);
				return caught;
			})
		)
	);

	public openDeleteConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openDeleteConfirmationPopup, confirmBeforeDeleteExpoOnList),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.publication.api.confirmation.popup.delete.header:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.expositionLabel,
							action: ExpositionAction.Delete,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			switchMap(([action]) => {
				if (Array.isArray(action.expositionId)) {
					return combineAll({
						action: of(action),
						res: combineLatest(
							action.expositionId.map((expId) =>
								this.expositionRequester.deleteExposition(expId as number)
							)
						),
					});
				}
				return combineAll({
					action,
					resp: this.expositionRequester.deleteExposition(action.expositionId),
				});
			}),
			map(({ action }) => {
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.delete.success:`
				);
				if (action.type === ExpositionsActions.ConfirmBeforeDeleteExpoOnList) {
					return refreshExpositionsList({
						expositionId: undefined,
					});
				}
				return goToExpositionsListRoute();
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.delete.error:`
				);
				return caught;
			})
		)
	);

	public openDeleteAccessConfirmationPopup$ = createEffect(() =>
		this.actions$.pipe(
			ofType(openDeleteAccessPopup),
			switchMap((action) => {
				const modalRef =
					this.modalService.openPopup<IExpositionActionConfirmationModalData>(
						ExpositionActionConfirmationPopupComponent,
						{
							title: $localize`:i18n=@@expositions.delete.access.popup.title:`,
							subTitle: '',
							expositionId: action.expositionId,
							element: action.accessLabel,
							action: ExpositionAction.DeleteAccess,
						},
						{
							disableClose: true,
						}
					);
				return combineLatest([of(action), modalRef.afterClosed()]);
			}),
			filter(([, modalResult]) => modalResult !== undefined),
			switchMap(([action]) =>
				combineLatest([
					this.expositionRequester.deleteExpositionAccess(
						action.expositionId,
						action.accessId
					),
					of(action),
				])
			),
			map(([res, action]) => {
				this.modalService.closeActiveModal();
				this.ngCommService.notifyOnSuccess(
					$localize`:i18n=@@expositions.delete.success:`
				);
				return deleteExpositionAccessSuccess({
					expositionId: action.expositionId,
					accessId: action.accessId,
				});
			}),
			catchError((error: HttpErrorResponse, caught) => {
				console.error(error);
				this.ngCommService.notifyOnError(
					$localize`:i18n=@@expositions.deleteAccess.error:`
				);
				return caught;
			})
		)
	);

	public constructor(
		private readonly actions$: Actions,
		private readonly store: Store,
		private readonly uuidService: UuidService,
		private readonly selector: ExpositionsSelector,
		private readonly modalService: AppModalService,
		private readonly stateService: AjsStateService,
		private readonly expositionDatablockRequester: ShareableDatablockRequester,
		private readonly dcViewRequester: DcViewRequester,
		private readonly expositionRequester: ExpositionRequester,
		private readonly expPermissionRequester: ExpositionPermissionRequester,
		private readonly tagRequester: TagRequester,
		private readonly expositionsAdapter: ExpositionsAdapter,
		private readonly ngCommService: NgCommService,
		private readonly ajsScopeService: AjsScopeService
	) {}
}
