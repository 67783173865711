import { immerable, produce } from 'immer';

export interface IExpositionPreviousConsumerEntity {
	id: string;
	fullName: string;
	description: string;
	unRecognized: boolean;
	isGroup: boolean;
	targetConsumerId: string | number | null;
	targetConsumerIsGroup: boolean | null;
}

const entityDefaults: IExpositionPreviousConsumerEntity = {
	id: '',
	fullName: '',
	description: '',
	unRecognized: true,
	isGroup: false,
	targetConsumerId: null,
	targetConsumerIsGroup: null,
};

export class ExpositionConsumerMappingEntity
	implements IExpositionPreviousConsumerEntity
{
	private [immerable] = true;
	public id = entityDefaults.id;
	public isGroup = entityDefaults.isGroup;
	public fullName = entityDefaults.fullName;
	public description = entityDefaults.description;
	public unRecognized = entityDefaults.unRecognized;
	public targetConsumerId = entityDefaults.targetConsumerId;
	public targetConsumerIsGroup = entityDefaults.targetConsumerIsGroup;

	public static build(
		params: Partial<IExpositionPreviousConsumerEntity>
	): ExpositionConsumerMappingEntity {
		const instance = new ExpositionConsumerMappingEntity();
		instance.id = params.id ?? entityDefaults.id;
		instance.isGroup = params.isGroup ?? entityDefaults.isGroup;
		instance.fullName = params.fullName ?? entityDefaults.fullName;
		instance.description = params.description ?? entityDefaults.description;
		instance.unRecognized = params.unRecognized ?? entityDefaults.unRecognized;
		instance.targetConsumerId =
			params.targetConsumerId ?? entityDefaults.targetConsumerId;
		instance.targetConsumerIsGroup =
			params.targetConsumerIsGroup ?? entityDefaults.targetConsumerIsGroup;
		return instance;
	}

	public mapTo(
		consumerId: number,
		isGroup: boolean
	): ExpositionConsumerMappingEntity {
		return produce(this, (draft: ExpositionConsumerMappingEntity) => {
			draft.targetConsumerId = consumerId;
			draft.targetConsumerIsGroup = isGroup;
		});
	}
}
