import { Injectable } from '@angular/core';
import { UrlService } from '@dc-common-core';
import { Observable } from 'rxjs';

import { DcBaseRequester } from '../core/dc-base.requester';

@Injectable()
export class EndpointRequester extends DcBaseRequester<{ response: boolean }> {
	protected resource;
	protected elementsListUrl;
	protected singleElementUrl;

	public constructor(private readonly urlService: UrlService) {
		super();
		this.resource = this.generateResourceUrl('EXPOSITION_AP_CHECK');
		this.elementsListUrl = this.resource;
		this.singleElementUrl = this.resource;
	}
	public validateEndpointName(
		checkValue: string,
		expositionId: number | null = null
	): Observable<boolean> {
		const url = this.urlService.generateUrl(
			this.resource,
			{},
			{
				expositionId,
				accessPoint: checkValue,
				project: this.projectId,
			}
		);
		return this.get<boolean>(url);
	}
}
