<ng-container *ngIf="vo$ | async; let vo">
	<app-page *ngIf="!vo.isLoading && !vo.embedded">
		<app-page-header></app-page-header>

		<app-page-actions>
			<button id="{{ cmpId }}-new-btn" *ngIf="vo.canCreate" matTooltip="new" i18n-matTooltip="@@general.action.new" matTooltipPosition="left"
							class="exposition-list-new" mat-icon-button color="primary"
							(click)="createNewExposition()">
				<mat-icon [svgIcon]="ActionIcons.AddExpositionAccess"></mat-icon>
			</button>
		</app-page-actions>

		<app-page-content>
			<ng-container [ngTemplateOutlet]="grid"></ng-container>
		</app-page-content>
	</app-page>

	<ng-container *ngIf="!vo.isLoading && vo.embedded" [ngTemplateOutlet]="grid"></ng-container>

	<ng-template #grid>
		<div *ngIf="vo.canCreate && vo.embedded" class="app-exposition-create-embedded">
			<button id="{{ cmpId }}-new-btn" matTooltip="new" i18n-matTooltip="@@general.action.new" matTooltipPosition="left"
							class="exposition-list-new" mat-icon-button color="primary"
							(click)="createNewExpositionFromEmbeddedView()">
				<mat-icon [svgIcon]="ActionIcons.AddExpositionAccess"></mat-icon>
			</button>
		</div>
		<app-panel
			headerHeight="3rem"
			isAlwaysOpen="true"
			hasSearchableGrid="true"
			hasRefresh="true"
			maxActionsCount="6"
			[actionsAvailable]="toggleActions"
			[itemsCount]="vo.list.elements.size"
			[actions]="columnsActions"
			(refresh)="refresh()"
			(search)="search($event)">
				<span panel-title i18n="@@expositions.list">
					Expositions
				</span>
			<span panel-info>
					<div *ngIf="selectionCount">
						<span>{{ selectionCount }} &#32;</span>
						<span i18n="@@expositions.columns.config.selectedElements">Elements selectionnée</span>
					</div>
				</span>
			<dx-data-grid
				keyExpr="id"
				[elementAttr]="{
				id: 'expositions-list',
				class: 'app-exposition-list'
			}"
				[dataSource]="vo.list.elements | toArray"
				[hoverStateEnabled]="true"
				[showColumnHeaders]="true"
				[allowColumnResizing]="false"
				[columnAutoWidth]="false"
				[showRowLines]="false"
				[showColumnLines]="false"
				[rowAlternationEnabled]="false"
				[showBorders]="false"
				[width]="'100%'"
				(onCellHoverChanged)="onCellHover($event)"
				(onSelectionChanged)="onSelectionChange($event)">
				<dxo-selection mode="multiple" showCheckBoxesMode="always"></dxo-selection>

				<dxo-header-filter [visible]="true"></dxo-header-filter>

				<dxo-load-panel
					shadingColor="rgba(0,0,0,0.4)"
					indicatorSrc="/src/img/loader-duotone.svg"
					[height]="100"
					[width]="100"
					[enabled]="true"
					[showIndicator]="true"
					[showPane]="true"
					[shading]="true"></dxo-load-panel>

				<!-- Fixable in position columns	-->
				<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

				<ng-container *ngFor="let col of vo.list.availableColumns | toArray">
					<dxi-column
						*ngIf="col.field === 'label'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[visible]="col.visible"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[format]="col.format"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[width]="200"
						cellTemplate="expositionColTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'apiStatus'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[format]="col.format"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[width]="140"
						cellTemplate="expositionStatusTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'columnsCount'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable ?? false"
						[allowSorting]="true"
						[format]="col.format"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						cellTemplate="configurationTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'datablockSource'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						cellTemplate="datablockSourceTemplate">
					</dxi-column>
					<dxi-column
						*ngIf="col.field === 'accessPointLabel'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[width]="200"
						cellTemplate="accessPointTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'duration'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[width]="80"
						cellTemplate="durationTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'dataEntries'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[width]="80"
						cellTemplate="dataLinesTemplate"></dxi-column>
					<!--						<dxi-column-->
					<!--							*ngIf="col.field === 'publishState'"-->
					<!--							[dataField]="col.field"-->
					<!--							[caption]="col.alias"-->
					<!--							[groupIndex]="col.groupIdx"-->
					<!--							[fixed]="false"-->
					<!--							[allowResizing]="true"-->
					<!--							[allowHiding]="true"-->
					<!--							[allowGrouping]="true"-->
					<!--							[allowSearch]="col.isSearchable"-->
					<!--							[allowFiltering]="col.isFilterable"-->
					<!--							[allowSorting]="true"-->
					<!--							[alignment]="'left'"-->
					<!--							[visibleIndex]="col.visibleIndex"-->
					<!--							[allowHeaderFiltering]="col.hasHeaderFilter"-->
					<!--							[width]="140"-->
					<!--							cellTemplate="publishStateTemplate"></dxi-column>-->
					<dxi-column
						*ngIf="col.field === 'lastElemModification'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[type]="col.type"
						[format]="col.format"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						cellTemplate="lastElemModificationTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'lastElemModificationBy'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="false"
						[alignment]="'left'"
						[type]="col.type"
						[format]="col.format"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[width]="40"
						cellTemplate="lastElemModificationByTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'lastExpositionAction'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[type]="col.type"
						[format]="col.format"
						cellTemplate="lastExpositionActionTemplate"></dxi-column>
					<dxi-column
						*ngIf="col.field === 'lastExpositionActionBy'"
						[dataField]="col.field"
						[caption]="col.alias"
						[groupIndex]="col.groupIdx"
						[fixed]="false"
						[allowResizing]="true"
						[allowHiding]="true"
						[allowGrouping]="true"
						[allowSearch]="col.isSearchable"
						[allowFiltering]="col.isFilterable"
						[allowSorting]="true"
						[alignment]="'left'"
						[visibleIndex]="col.visibleIndex"
						[allowHeaderFiltering]="col.hasHeaderFilter"
						[type]="col.type"
						[format]="col.format"
						[width]="40"
						cellTemplate="lastExpositionActionByTemplate"></dxi-column>
				</ng-container>

				<dxi-column
					[fixed]="false"
					[allowResizing]="false"
					[allowHiding]="false"
					[allowGrouping]="false"
					[allowSearch]="false"
					[allowSorting]="false"
					[alignment]="'left'"
					[visibleIndex]="13"
					[width]="70"
					cellTemplate="actionsTemplate"></dxi-column>

				<div class="" *dxTemplate="let cell of 'dataLinesTemplate'">
					<span *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished">{{cell.data['dataEntries']}}</span>
				</div>

					<div class="configuration-column-template" *dxTemplate="let cell of 'configurationTemplate'">
						<ng-container *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished">
							<app-overlay-badge
								[active]="false"
								[icon]="ComponentIcons.Column"
								[value]="cell.data['columnsCount']">
							</app-overlay-badge>
							<app-overlay-badge
								[active]="false"
								[icon]="ComponentIcons.Access"
								[value]="cell.data['accessCount']">
							</app-overlay-badge>
							<app-overlay-badge id="overlaybadge-endpointMetadata"
								[active]="false"
								[icon]="ComponentIcons.PublicEndpointMetadata"
								[isOpen]="vo.openedOverlays.endpointMetadata === cell.data['id']" (overlayDismiss)="closeOverlay()"
								(click)="openEndpointMetadataPopover($event, cell.data)">
								<app-popover id="popover-endpointMetadata" overlayContent title="endpoint"
														 i18n-title="@@exposition.metadata.public"
														 [icon]="{iconName: ComponentIcons.PublicEndpointMetadata}"
														 (adbPopoverClose)="closeOverlay()">
									<span *ngIf="vo.isLoadingMetadata" class="loading-spinner">
										<mat-spinner></mat-spinner>
									</span>
									<app-exposition-endpoint-metadata-config
										*ngIf="!vo.isLoadingMetadata"
										[expositionMetadata]="vo.endpointMetadata"
										[isInViewMode]="true"></app-exposition-endpoint-metadata-config>
								</app-popover>
							</app-overlay-badge>
						</ng-container>
					</div>

				<div class="" *dxTemplate="let cell of 'expositionStatusTemplate'">
					<app-exposition-publication-status
						[status]="cell.data['actionInProgress'] === 'none' ?
							cell.data['apiStatus']
							: PublicationStatus.Running">
					</app-exposition-publication-status>
				</div>

				<div
					*dxTemplate="let cell of 'expositionColTemplate'"
					class="label-column-template">
						<span [matTooltip]="cell.data['label']"
									(click)="navigateToExposition(cell.data['id'])"
									[matTooltipDisabled]="cell.data['label'].length < 24"
									matTooltipPosition="left" class="label-column-template__label">
							{{ cell.data['label'] | truncate:24 }}
						</span>

					<app-tag-configurator *ngIf="cell.data['tags'].length !== 0"
																displayMode="tags" configMode="View"
																[configuredTags]="cell.data['tags']"></app-tag-configurator>
				</div>

				<div *dxTemplate="let cell of 'accessPointTemplate'">
					<h6 [matTooltip]="cell.data['accessPointUrl']"
							matTooltipPosition="left">
						{{cell.data['accessPointLabel'] | truncate:24}}
					</h6>
				</div>

				<div class="datablock-source-column-template" *dxTemplate="let cell of 'datablockSourceTemplate'">
						<span [matTooltip]="cell.data['datablockSource']"
									[matTooltipDisabled]="cell.data['datablockSource'].length < 24"
									matTooltipPosition="left">{{cell.data['datablockSource'] | truncate:24 }}</span>
					<a appNavigate [hidden]="cell.rowIndex !== currentlyHovered" [navigateTo]="cell.data['sourceUrl']">
						<mat-icon
							[svgIcon]="DcIcons.OpenInNewTab"
							matTooltip="open exposition in new tab"
							i18n-matTooltip="@@exposition.list.sourceTooltip"
							matTooltipPosition="after"
						></mat-icon>
					</a>
				</div>

				<!--					<div *dxTemplate="let cell of 'publishStateTemplate'">-->
				<!--						<app-exposition-publication-status *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished"-->
				<!--							[status]="cell.data['publishState']"></app-exposition-publication-status>-->
				<!--					</div>-->

				<div class="last-elem-modification-column-template" *dxTemplate="let cell of 'lastElemModificationTemplate'">
					<span>{{cell.data['lastElemModification'] | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
				</div>

				<div class="last-elem-modification-column-template" *dxTemplate="let cell of 'lastElemModificationByTemplate'">
					<app-avatar avatarSize="small" toolTipEnabled="true" [avatar]="cell.data['lastElemModificationBy']"></app-avatar>
				</div>

				<div class="last-exposition-action-column-template" *dxTemplate="let cell of 'lastExpositionActionTemplate'">
					<span *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished">{{cell.data['lastExpositionAction'] | date: 'yyyy-MM-dd HH:mm:ss' }}</span>
				</div>

				<div class="last-exposition-action-column-template" *dxTemplate="let cell of 'lastExpositionActionByTemplate'">
					<app-avatar *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished" avatarSize="small" toolTipEnabled="true" [avatar]="cell.data['lastExpositionActionBy']"></app-avatar>
				</div>

				<div *dxTemplate="let cell of 'durationTemplate'">
					<span *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished">{{cell.data['duration']}}</span>
				</div>

				<div *dxTemplate="let cell of 'actionsTemplate'" class="actions-column-template">
					<div [hidden]="cell.rowIndex !== currentlyHovered">
						<button *ngIf="cell.data['apiStatus'] !== PublicationStatus.Unpublished" mat-icon-button
										class="actions-column-template__menu-btn"
										matTooltip="update-expose"
										i18n-matTooltip="@@expositions.list.data"
										matTooltipPosition="left" (click)="updateExpositionData(cell.data)">
							<mat-icon [svgIcon]="ActionIcons.UpdateExpositionData"></mat-icon>
						</button>
						<button *ngIf="cell.data['apiStatus'] === PublicationStatus.Unpublished" mat-icon-button
										class="actions-column-template__menu-btn"
										matTooltip="publish-expose"
										i18n-matTooltip="@@expositions.list.publish"
										matTooltipPosition="left" (click)="publishExposition(cell.data)">
							<mat-icon [svgIcon]="ActionIcons.PublishExposition"></mat-icon>
						</button>
						<button id="{{ cmpId }}-{{ cell.rowIndex }}-actions-menu-btn"
										mat-icon-button
										class="actions-column-template__menu-btn"
										(click)="rowMenuOpen($event, cell.rowIndex)"
										[matMenuTriggerFor]="cell.data['apiStatus'] === PublicationStatus.Unpublished ? unpublishedActions :
								cell.data['apiStatus'] === PublicationStatus.Available ? publishedActiveActions :
								publishedInActiveActions"
										[matMenuTriggerData]="{ $implicit: cell.data}">
							<mat-icon [svgIcon]="DcIcons.MenuMoreOptionsHandle"></mat-icon>
						</button>
					</div>

					<mat-menu #publishedInActiveActions="matMenu">
						<ng-template matMenuContent let-data>
							<button *ngFor="let a of rowMenuPublishedInActiveActions"
											id="{{ cmpId }}-{{ cell.rowIndex }}-{{a.actionName}}-btn"
											(click)="a.action(data)"
											mat-menu-item>
								<mat-icon *ngIf="a.icon" [svgIcon]="a.icon"></mat-icon>
								<span>{{a.description}}</span>
							</button>
						</ng-template>
					</mat-menu>

					<mat-menu #publishedActiveActions="matMenu">
						<ng-template matMenuContent let-data>
							<button *ngFor="let a of rowMenuPublishedActiveActions"
											id="{{ cmpId }}-{{ cell.rowIndex }}-{{a.actionName}}-btn"
											(click)="a.action(data)"
											mat-menu-item>
								<mat-icon *ngIf="a.icon" [svgIcon]="a.icon"></mat-icon>
								<span>{{a.description}}</span>
							</button>
						</ng-template>
					</mat-menu>
					<mat-menu #unpublishedActions="matMenu">
						<ng-template matMenuContent let-data>
							<button *ngFor="let a of rowMenuNotPublishedActions"
											id="{{ cmpId }}-{{ cell.rowIndex }}-{{a.actionName}}-btn"
											(click)="a.action(data)"
											mat-menu-item>
								<mat-icon *ngIf="a.icon" [svgIcon]="a.icon"></mat-icon>
								<span>{{a.description}}</span>
							</button>
						</ng-template>
					</mat-menu>
				</div>

				<dxo-group-panel [visible]="true"></dxo-group-panel>

				<!-- Sorting	-->
				<dxo-sorting mode="multiple"></dxo-sorting>

				<!-- Pagination -->
				<dxo-paging [pageSize]="50"></dxo-paging>

				<dxo-column-chooser
					[allowSearch]="true"
					[enabled]="false"
					[mode]="'select'">
					<dxo-selection
						[allowSelectAll]="true"
						[recursive]="false"></dxo-selection>
				</dxo-column-chooser>

				<!-- State persistence	-->
				<dxo-state-storing
					type="localStorage"
					[savingTimeout]="0"
					[enabled]="true"
					[storageKey]="dxLocalStorageKey"></dxo-state-storing>
			</dx-data-grid>
		</app-panel>
	</ng-template>
</ng-container>
