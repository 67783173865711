import { CommonModule } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DcLibUiModule } from '@dc-common-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
	DxButtonModule,
	DxDataGridModule,
	DxTextBoxModule,
	DxValidatorModule,
} from 'devextreme-angular';

import { MaterialModule } from '../ui/material.module';
import { UiModule } from '../ui/ui.module';
import { ExpositionAccessConfigComponent } from './components/exposition-access-config/exposition-access-config.component';
import { ExpositionAccessListComponent } from './components/exposition-access-list/exposition-access-list.component';
import { ExpositionAccessModalComponent } from './components/exposition-access-modal/exposition-access-modal.component';
import { ExpositionActionConfirmationPopupComponent } from './components/exposition-action-confirmation-popup/exposition-action-confirmation-popup.component';
import { ExpositionColumnsConfigComponent } from './components/exposition-columns-config/exposition-columns-config.component';
import { ExpositionColumnsModalComponent } from './components/exposition-columns-modal/exposition-columns-modal.component';
import { ExpositionConsumerListComponent } from './components/exposition-consumers-list/exposition-consumer-list.component';
import { ExpositionConsumersMappingComponent } from './components/exposition-consumers-mapping/exposition-consumers-mapping.component';
import { ExpositionDatablockListComponent } from './components/exposition-datablock-list/exposition-datablock-list.component';
// eslint-disable-next-line max-len
import { ExpositionEndpointMetadataConfigComponent } from './components/exposition-endpoint-metadata-config/exposition-endpoint-metadata-config.component';
// eslint-disable-next-line max-len
import { ExpositionEndpointMetadataPopupComponent } from './components/exposition-endpoint-metadata-popup/exposition-endpoint-metadata-popup.component';
import { ExpositionHistoryListComponent } from './components/exposition-history-list/exposition-history-list.component';
import { ExpositionInternalMetadataConfigComponent } from './components/exposition-internal-metadata-config/exposition-internal-metadata.config.component';
import { ExpositionListComponent } from './components/exposition-list/exposition-list.component';
import { ExpositionMetadataPopupComponent } from './components/exposition-metadata-popup/exposition-metadata-popup.component';
import { ExpositionMigrationStepperComponent } from './components/exposition-migration-stepper/exposition-migration-stepper.component';
import { ExpositionMigrationsListComponent } from './components/exposition-migrations-list/exposition-migrations-list.component';
import { ExpositionProjectImportConsumersMappingComponent } from './components/exposition-project-import-consumers-mapping/exposition-project-import-consumers-mapping.component';
import { ExpositionPublicationStatusComponent } from './components/exposition-publication-status/exposition-publication-status.component';
import { ExpositionStepperComponent } from './components/exposition-stepper/exposition-stepper.component';
import { ExpositionViewComponent } from './components/exposition-view/exposition-view.component';
import { PublishedExpositionComponent } from './components/published-exposition/published-exposition.component';
import { ExpositionsAdapter } from './expositions.adapter';
import { NgExpositions } from './ng-expositions';
import {
	ExpositionsEffects,
	expositionsReducer,
	ExpositionsSelector,
	ExpositionsState,
} from './store';

@NgModule({
	declarations: [
		ExpositionListComponent,
		ExpositionStepperComponent,
		ExpositionDatablockListComponent,
		ExpositionInternalMetadataConfigComponent,
		ExpositionEndpointMetadataConfigComponent,
		ExpositionColumnsConfigComponent,
		ExpositionAccessListComponent,
		ExpositionAccessConfigComponent,
		ExpositionConsumerListComponent,
		ExpositionViewComponent,
		ExpositionColumnsModalComponent,
		ExpositionAccessModalComponent,
		PublishedExpositionComponent,
		ExpositionMetadataPopupComponent,
		ExpositionEndpointMetadataPopupComponent,
		ExpositionPublicationStatusComponent,
		ExpositionActionConfirmationPopupComponent,
		ExpositionMigrationsListComponent,
		ExpositionMigrationStepperComponent,
		ExpositionHistoryListComponent,
		ExpositionConsumersMappingComponent,
		ExpositionProjectImportConsumersMappingComponent,
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		StoreModule.forFeature(ExpositionsState.key, expositionsReducer),
		EffectsModule.forFeature([ExpositionsEffects]),
		UiModule,
		DcLibUiModule,
		MaterialModule,
		DxDataGridModule,
		FormsModule,
		DxButtonModule,
		DxTextBoxModule,
		DxValidatorModule,
	],
	providers: [NgExpositions, ExpositionsSelector, ExpositionsAdapter],
})
export class ExpositionsModule {
	public constructor(private readonly injector: Injector) {
		const expositionsListCmpElement = createCustomElement(
			ExpositionListComponent,
			{
				injector,
			}
		);
		customElements.define('app-expositions-list', expositionsListCmpElement);

		const expositionViewCmpElement = createCustomElement(
			ExpositionViewComponent,
			{
				injector,
			}
		);
		customElements.define('app-exposition-view', expositionViewCmpElement);

		const expositionMigrationsListCmpElement = createCustomElement(
			ExpositionMigrationsListComponent,
			{
				injector,
			}
		);
		customElements.define(
			'app-exposition-migrations-list',
			expositionMigrationsListCmpElement
		);

		const expositionHistoryCmpElement = createCustomElement(
			ExpositionHistoryListComponent,
			{
				injector,
			}
		);
		customElements.define(
			'app-exposition-history-list',
			expositionHistoryCmpElement
		);

		const expositionConsumersMappingCmpElement = createCustomElement(
			ExpositionProjectImportConsumersMappingComponent,
			{
				injector,
			}
		);
		customElements.define(
			'app-project-consumers-mapping',
			expositionConsumersMappingCmpElement
		);
	}
}
