<app-modal *ngIf="vo$ | async; let vo">
	<button mat-icon-button modal-close-btn (click)="close()">
		<mat-icon
			[svgIcon]="DcIcons.Close"
		></mat-icon>
	</button>

	<ng-container modal-body>
		<mat-stepper linear="true"
								 (selectionChange)="changeCurrentStep($event)"
								 (animationDone)="onStepReady()">
			<!--	Exposition selection step		-->
			<mat-step [completed]="vo.stepsCompletion.expositionSelection">
				<ng-template matStepLabel>
					<span i18n="@@exposition.migration.stepper.firstStep.title">exposition_selection</span>
				</ng-template>

				<p i18n="@@exposition.migration.stepper.firstStep.description1">message1</p>
				<p i18n="@@exposition.migration.stepper.firstStep.description2">message2</p>

				<app-panel headerHeight="3rem" isAlwaysOpen="true"
									 hasSearchableGrid="true"
									 hasRefresh="true"
									 [itemsCount]="vo.readyForMigration.elements.size"
									 (refresh)="refresh()"
									 (search)="searchForExpositionToMigrate($event)">
					<span panel-title i18n="@@exposition.migration.stepper.firstStep.grid.title">v1_expositions</span>
					<div *ngIf="vo.readyForMigration">
						<dx-data-grid #expositionReadyForMigrationDatagrid
							keyExpr="sourceDatablockId"
							[elementAttr]="{
								id: 'exposition-ready-for-migration-datagrid',
								class: 'app-exposition-column-config'
							}"
							[repaintChangesOnly]="true"
							[dataSource]="vo.readyForMigration.elements | toArray"
							[hoverStateEnabled]="true"
							[showColumnHeaders]="true"
							[allowColumnResizing]="true"
							[columnAutoWidth]="false"
							[showRowLines]="false"
							[showColumnLines]="false"
							[rowAlternationEnabled]="true"
							[showBorders]="false"
							[width]="'100%'"
							(onRowClick)="onExpositionClick($event)"
							(onSelectionChanged)="onExpositionSelectionChange($event)">
							<dxo-selection
								*ngIf="!vo.isInViewMode"
								mode="multiple"
								[showCheckBoxesMode]="'always'"></dxo-selection>

							<dxo-scrolling mode="virtual"></dxo-scrolling>
							<!-- Fixable in position columns	-->
							<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

							<dxo-load-panel
								shadingColor="rgba(0,0,0,0.4)"
								indicatorSrc="/src/img/loader-duotone.svg"
								[height]="100"
								[width]="100"
								[enabled]="true"
								[showIndicator]="true"
								[showPane]="true"
								[shading]="true"></dxo-load-panel>

							<ng-container
								*ngFor="let col of vo.readyForMigration.availableColumns | toArray">
								<dxi-column
									*ngIf="col.field === 'label'"
									[dataField]="col.field"
									[caption]="col.alias"
									[groupIndex]="col.groupIdx"
									[visibleIndex]="col.visibleIndex"
									[fixed]="false"
									[allowResizing]="true"
									[allowHiding]="true"
									[allowGrouping]="true"
									[allowSearch]="true"
									[allowFiltering]="col.isSearchable"
									[allowSorting]="true"
									[format]="col.format"
									[alignment]="'left'"
									[allowEditing]="false"
									[width]="'50%'"
									cellTemplate="labelTemplate"></dxi-column>

								<dxi-column
									*ngIf="col.field === 'accessPoint'"
									[dataField]="col.field"
									[caption]="col.alias"
									[groupIndex]="col.groupIdx"
									[visibleIndex]="col.visibleIndex"
									[fixed]="false"
									[allowResizing]="true"
									[allowHiding]="true"
									[allowGrouping]="true"
									[allowSearch]="col.isSearchable"
									[allowSorting]="true"
									[format]="col.format"
									[alignment]="'left'"
									[allowEditing]="false"
									[width]="'50%'"
									cellTemplate="accessPointTemplate"></dxi-column>
							</ng-container>

							<div *dxTemplate="let cell of 'labelTemplate'">
								<span>{{ cell.data['label'] }}</span>
								<app-tag-configurator
									configMode="View"
									displayMode="tags"
									[configuredTags]="cell.data['tags']"></app-tag-configurator>
							</div>

							<div *dxTemplate="let cell of 'accessPointTemplate'">
								<span>{{ cell.data['accessPoint'] }}</span>
							</div>

							<dxo-group-panel [visible]="false"></dxo-group-panel>

							<!-- Search input -->
							<dxo-search-panel [visible]="false"></dxo-search-panel>

							<!-- Sorting	-->
							<dxo-sorting mode="multiple"></dxo-sorting>

							<!-- Pagination -->
							<dxo-paging [pageSize]="1000"></dxo-paging>

							<dxo-pager [visible]="false"></dxo-pager>
							<!-- State persistence	-->
							<dxo-state-storing
								type="localStorage"
								[enabled]="false"
								[storageKey]="dxLocalStorageKey"></dxo-state-storing>
						</dx-data-grid>
					</div>
				</app-panel>
			</mat-step>

			<!--	Consumers Mapping step		-->
			<mat-step [completed]="vo.stepsCompletion.consumersMapping">
				<ng-template matStepLabel>
					<span i18n="@@exposition.migration.stepper.secondStep.title">consumers_mapping</span>
				</ng-template>

				<p i18n="@@exposition.migration.stepper.secondStep.description1">mapping</p>

				<app-exposition-consumers-mapping
				[availableConsumers]="vo.availableConsumers"
				[consumersToMap]="vo.consumersToMap"
				(reloadUsers)="reloadUsers()"
				(assignTargetConsumer)="assignTargetConsumer($event)">
				</app-exposition-consumers-mapping>
			</mat-step>
		</mat-stepper>
	</ng-container>

	<div modal-footer class="app-exposition-migration-stepper__footer">
		<button
			*ngIf="vo.currentStep === MigrationStep.ExpositionsSelection"
			id="{{ cmpId }}-next-btn"
			class="app-exposition-migration-stepper__footer"
			mat-raised-button
			[disabled]="!vo.stepsCompletion.expositionSelection"
			(click)="nextStep()"
			color="primary">
			<span i18n="@@general.stepper.next">Next</span>
		</button>
		<button
			*ngIf="vo.currentStep === MigrationStep.ConsumersMapping"
			id="{{ cmpId }}-back-btn"
			mat-raised-button
			(click)="previousStep()">
			<span i18n="@@general.stepper.back">Back</span>
		</button>
		<button
			*ngIf="vo.currentStep === MigrationStep.ConsumersMapping"
			id="{{ cmpId }}-create-btn"
			mat-raised-button
			[disabled]="!vo.stepsCompletion.consumersMapping"
			(click)="migrate()"
			color="primary">
			<span class="app-exposition-migration-stepper__footer-btn">
				<mat-icon
					*ngIf="!vo.migrationInProgress"
					[svgIcon]="DcIcons.Save"></mat-icon>
				<mat-icon *ngIf="vo.migrationInProgress">
					<mat-spinner color="accent" [diameter]="20"></mat-spinner>
				</mat-icon>
				<span i18n="@@exposition.migration.stepper.secondStep.migrate">finalize_migrate</span>
			</span>
		</button>
	</div>
</app-modal>
