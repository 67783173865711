import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import {
	AppTagConfiguratorComponent,
	AppTagItemEntity,
	combineAll,
	DcBaseComponent,
} from '@dc-common-core';
import { OrderedSet } from 'immutable';
import { debounceTime, merge, Observable, of, takeUntil, tap } from 'rxjs';

import { EndpointRequester } from '../../../requesters/async-validators/endpoint.requester';
import { ValidationErrorKeys } from '../../../ui/form/validation-erros-keys';
import { ExpositionEndpointMetadataEntity } from './exposition-endpoint-metadata.entity';
import {
	ExpositionEndpointMetadataForm,
	EndpointMetadataFormControls,
} from './exposition-endpoint-metadata.form';

@Component({
	selector: 'app-exposition-endpoint-metadata-config',
	templateUrl: './exposition-endpoint-metadata-config.component.html',
	styleUrls: ['./exposition-endpoint-metadata-config.component.scss'],
	inputs: ['expositionMetadata', 'isInViewMode', 'expositionId'],
	providers: [ExpositionEndpointMetadataForm, EndpointRequester],
})
export class ExpositionEndpointMetadataConfigComponent extends DcBaseComponent {
	public Appearance: MatFormFieldAppearance = 'legacy';
	public EndpointMetadataFormControls = EndpointMetadataFormControls;

	@ViewChild(AppTagConfiguratorComponent)
	public tagConfiguratorComponentCmp: AppTagConfiguratorComponent | null = null;

	@Output()
	public hasEndPointMetadataChanged = new EventEmitter<boolean>();

	public vo$: Observable<{
		expositionMetadata: ExpositionEndpointMetadataEntity;
		isInViewMode: boolean;
	}>;
	protected readonly ValidationErrorKeys = ValidationErrorKeys;
	public constructor(
		public readonly endpointMetadataForm: ExpositionEndpointMetadataForm
	) {
		super();
		this.cmpId = 'exposition-endpoint-metadata-config';
		combineAll({
			endpointMetadata:
				this.toObservable<ExpositionEndpointMetadataEntity>(
					'expositionMetadata'
				),
			expositionId: merge(of(null), this.toObservable<number>('expositionId')),
			isInViewMode: merge(
				of(false),
				this.toObservable<boolean>('isInViewMode')
			),
		})
			.pipe(
				takeUntil(this.onDestroy$),
				tap(({ endpointMetadata, expositionId, isInViewMode }) => {
					this.endpointMetadataForm.populate(
						endpointMetadata,
						expositionId,
						isInViewMode
					);
				})
			)
			.subscribe();

		this.endpointMetadataForm.form.valueChanges
			.pipe(
				takeUntil(this.onDestroy$),
				debounceTime(1000),
				tap(() => {
					this.hasEndPointMetadataChanged.emit();
				})
			)
			.subscribe();

		// this.endpointMetadataForm.form.statusChanges
		// 	.pipe(
		// 		takeUntil(this.onDestroy$),
		// 		tap((st) => console.log(st))
		// 	)
		// 	.subscribe();

		this.vo$ = combineAll({
			expositionMetadata: merge(
				of(ExpositionEndpointMetadataEntity.build({})),
				this.toObservable<ExpositionEndpointMetadataEntity>(
					'expositionMetadata'
				)
			),
			isInViewMode: merge(
				of(false),
				this.toObservable<boolean>('isInViewMode')
			),
		});
	}

	public async extractFormData(): Promise<ExpositionEndpointMetadataEntity> {
		let keywords = new Array<AppTagItemEntity>();
		if (this.tagConfiguratorComponentCmp) {
			keywords = await this.tagConfiguratorComponentCmp.getUsedTags();
		}
		const endpointMetadata = this.endpointMetadataForm.extract();
		return ExpositionEndpointMetadataEntity.build({
			accessPoint: endpointMetadata?.accessPoint,
			title: endpointMetadata?.title,
			details: endpointMetadata?.details,
			keywords: OrderedSet(keywords),
		});
	}
}
