import { Injectable } from '@angular/core';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';

import { EndpointRequester } from '../../../requesters/async-validators/endpoint.requester';
import { EndpointNameValidator } from '../../endpoint-name.validator';
import { ExpositionEndpointMetadataEntity } from './exposition-endpoint-metadata.entity';

export enum EndpointMetadataFormControls {
	AccessPoint = 'access-point',
	Title = 'title',
	Details = 'details',
}

@Injectable()
export class ExpositionEndpointMetadataForm {
	public form: FormGroup;

	public constructor(
		protected readonly fb: FormBuilder,
		private readonly endpointRequester: EndpointRequester
	) {
		this.form = this.form = this.fb.group({
			[EndpointMetadataFormControls.AccessPoint]: [
				'',
				[
					Validators.required,
					Validators.maxLength(50),
					Validators.minLength(4),
					Validators.pattern('^[a-zA-Z][a-zA-Z0-9_]*$'),
				],
			],
			[EndpointMetadataFormControls.Title]: [
				'',
				[Validators.required, Validators.maxLength(256)],
			],
			[EndpointMetadataFormControls.Details]: [
				'',
				[Validators.maxLength(2500)],
			],
		});
	}

	public extract(): Partial<ExpositionEndpointMetadataEntity> {
		if (!this.form) {
			throw new Error('no form found');
		}
		const formValues = this.form.value;
		return ExpositionEndpointMetadataEntity.build({
			accessPoint: formValues[EndpointMetadataFormControls.AccessPoint] ?? -1,
			title: formValues[EndpointMetadataFormControls.Title] ?? '',
			details: formValues[EndpointMetadataFormControls.Details] ?? '',
		});
	}

	public populate(
		config: Partial<ExpositionEndpointMetadataEntity> | null,
		expositionId: number | null = null,
		isInViewMode: boolean
	): void {
		if (config === null || config.id === undefined) {
			throw new Error('Invalid entity');
		}
		if (!isInViewMode) {
			const ctrl = this.form.get(
				EndpointMetadataFormControls.AccessPoint
			) as FormControl;

			ctrl.clearAsyncValidators();
			ctrl.addAsyncValidators(
				EndpointNameValidator.createValidator(
					expositionId,
					this.endpointRequester
				)
			);
		}
		this.form.patchValue({
			[EndpointMetadataFormControls.AccessPoint]: config?.accessPoint ?? '',
			[EndpointMetadataFormControls.Title]: config?.title ?? '',
			[EndpointMetadataFormControls.Details]: config?.details ?? '',
		});
		this.form.updateValueAndValidity();
	}
}
