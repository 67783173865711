<app-modal *ngIf="vo$ | async; let vo">
	<button mat-icon-button modal-close-btn (click)="close()">
		<mat-icon
			[svgIcon]="Icons.Close"
			></mat-icon>
	</button>

	<ng-container modal-body>
		<mat-stepper
			[linear]="true"
			[selectedIndex]="vo.currentStep"
			(selectionChange)="changeCurrentStep($event)"
			(animationDone)="onStepReady()">
			<!--	Datablock Selection Step		-->
			<mat-step [completed]="vo.stepsValidity.step1" [editable]="vo.isDatablockSelectionEnabled">
				<ng-template matStepLabel>
					<span i18n="@@expositions.stepper.datablock">datablock</span>
				</ng-template>
				<div
					class="app-exposition-stepper__step-description"
					i18n="@@expositions.stepper.datablock.description">
					Select exposure data source.
				</div>
				<app-panel
					headerHeight="3rem"
					isAlwaysOpen="true"
					hasSearchableGrid="true"
					hasRefresh="true"
					[itemsCount]="datablocksGrid.datablocksCount"
					(refresh)="refreshDatablocks()"
					(search)="datablocksGrid.search($event)">
					<span panel-title i18n="@@exposition.datablock.list.title">
						datablocks
					</span>
					<app-exposition-datablock-list
						#datablocksGrid="DatablocksGrid"
						(selectedDatablockIdEvent)="
							changeDatablockSelection($event)
						"></app-exposition-datablock-list>
				</app-panel>
			</mat-step>

			<!--	Element and Access point metadata Step		-->
			<mat-step [completed]="vo.stepsValidity.step2">
				<ng-template matStepLabel>
					<span i18n="@@expositions.stepper.metadata">metadata</span>
				</ng-template>
				<div
					class="app-exposition-stepper__step-description"
					i18n="@@expositions.stepper.metadata.description">
					Define internal metadata and metadata visible to consumers.
				</div>
				<div class="app-exposition-stepper__metadata-config">
					<app-card-outline>
						<div
							card-outline-title
							i18n="@@exposition.metadata.exposition.header">
							exposition
						</div>
						<app-exposition-internal-metadata-config
							card-outline-content
							[expositionMetadata]="vo.expositionMetadata"
							[availableTags]="vo.availableTags"
							(hasInternalMetadataChanged)="
								checkMetadataFormValidity()
							"></app-exposition-internal-metadata-config>
					</app-card-outline>

					<app-card-outline>
						<app-tooltip
							card-outline-title
							tooltipText="Les métadonnées de la Publication sont visibles par les consommateurs"
							i18n-tooltipText="@@exposition.metadata.publication.info">
							<div i18n="@@exposition.metadata.publication.header">
								publication
							</div>
						</app-tooltip>
						<app-exposition-endpoint-metadata-config
							card-outline-content
							[expositionMetadata]="vo.endpointMetadata"
							(hasEndPointMetadataChanged)="
								checkMetadataFormValidity()
							"></app-exposition-endpoint-metadata-config>
					</app-card-outline>
				</div>
			</mat-step>

			<!--	Data source Columns configuration  Step		-->
			<mat-step [completed]="vo.stepsValidity.step3">
				<ng-template matStepLabel>
					<span i18n="@@expositions.stepper.columns">columns</span>
				</ng-template>
				<div class="app-exposition-stepper__step-description">
					<app-tooltip
						tooltipText="API access parameters depend on this configuration"
						i18n-tooltipText="
							@@expositions.stepper.columns.description.infoTooltip">
						<span i18n="@@expositions.stepper.columns.description">
							Configure general column parameters
						</span>
					</app-tooltip>
				</div>
				<app-panel
					headerHeight="3rem"
					class="app-card-outline__columns"
					isAlwaysOpen="true"
					hasSearchableGrid="true"
					hasRefresh="true"
					[actionsAvailable]="columnsGrid.selectionCount"
					[itemsCount]="vo.columnsConfiguration.elements.size"
					[actions]="vo.columnsConfigurationPanelActions"
					(refresh)="refreshColumns()"
					(search)="columnsGrid.search($event)">
					<span panel-title i18n="@@exposition.access.list.column.columns">
						columns
					</span>
					<div class="exposition-column-step-panel-info" panel-info>
						<div *ngIf="columnsGrid.selectionCount">
							<span>{{ columnsGrid.selectionCount }}&#32;</span>
							<span i18n="@@expositions.columns.config.selectedElements">Elements selectionnée</span>
						</div>
						<ng-container *ngIf="vo.listErrors.size > 0 && vo.listErrors.has(ColumnConfigErrorKey.NoActiveColumns)">
							<div class="activated-columns-alert">
								<mat-icon color="warn" [svgIcon]="DcIcons.Warning"></mat-icon>
								<span	i18n="@@expositions.columns.config.alert.minOneActive">min one active column</span>
							</div>
						</ng-container>
						<ng-container *ngIf="vo.listErrors.size > 0 && vo.listErrors.has(ColumnConfigErrorKey.DuplicatedAlias)">
							<div class="activated-columns-alert">
								<mat-icon color="warn" [svgIcon]="DcIcons.Warning"></mat-icon>
								<span i18n="@@expositions.columns.config.alert.duplicateAlias">duplicated_alias</span>
							</div>
						</ng-container>
					</div>
					<app-exposition-columns-config
						#columnsGrid="ColumnsGrid"
						[columnConfigList]="vo.columnsConfiguration"
						(errors)="
							modifyColumnConfigValidity($event)
						"></app-exposition-columns-config>
				</app-panel>
			</mat-step>

			<!--	Access configuration Step		-->
			<mat-step [completed]="vo.stepsValidity.step4">
				<ng-template matStepLabel>
					<span i18n="@@expositions.stepper.access">access</span>
				</ng-template>
				<div
					class="app-exposition-stepper__step-description"
					i18n="@@expositions.stepper.access.description">
					Configure access to the API
				</div>
				<app-exposition-access-config
					isInStepperMode="'true'"
					[accessConfig]="vo.accessConfig"
					(hasValidityChanged)="
						changeAccessConfigValidity($event)
					"></app-exposition-access-config>
			</mat-step>
		</mat-stepper>
	</ng-container>

	<div modal-footer class="app-exposition-stepper__footer">
		<button
			*ngIf="vo.currentStep !== ExpositionStep.Datablock && vo.currentStep !== ExpositionStep.Metadata"
			id="{{ cmpId }}-back-btn"
			mat-raised-button
			(click)="previousStep()">
			<span i18n="@@general.stepper.back">Back</span>
		</button>
		<button
			*ngIf="vo.currentStep === ExpositionStep.Metadata && vo.isDatablockSelectionEnabled"
			id="{{ cmpId }}-back-btn"
			mat-raised-button
			(click)="previousStep()">
			<span i18n="@@general.stepper.back">Back</span>
		</button>
		<button
			*ngIf="vo.currentStep !== ExpositionStep.Access"
			id="{{ cmpId }}-next-btn"
			class="app-exposition-stepper__footer-btn"
			mat-raised-button
			[disabled]="!vo.isCurrentStepValid"
			(click)="nextStep()"
			color="primary">
			<span i18n="@@general.stepper.next">Next</span>
		</button>
		<button
			*ngIf="vo.currentStep === ExpositionStep.Access"
			id="{{ cmpId }}-create-btn"
			mat-raised-button
			[disabled]="!vo.isCurrentStepValid"
			(click)="create()"
			color="primary">
			<span class="app-exposition-stepper__footer-btn">
				<mat-icon
					*ngIf="!vo.isCreationInProgress"
					[svgIcon]="DcIcons.Save"></mat-icon>
				<mat-icon *ngIf="vo.isCreationInProgress">
					<mat-spinner color="accent" [diameter]="20"></mat-spinner>
				</mat-icon>
				<span i18n="@@general.stepper.create">Create</span>
			</span>
		</button>
	</div>
</app-modal>
