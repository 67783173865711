import { createReducer, on } from '@ngrx/store';

import {
	fetchAvailableExpositionsListSuccess,
	fetchDataBlockColumnsSuccess,
	fetchExpositionDataBlockListSuccess,
	fetchColumnConfigListSuccess,
	getDataBlocksColumns,
	getColumnConfigList,
	initExpositionsList,
	fetchAvailableTagsSuccess,
	fetchAvailableConsumersSuccess,
	createExposition,
	createExpositionSuccess,
	fetchExpositionSuccess,
	fetchExpositionColumnsSuccess,
	fetchExpositionMetadataSuccess,
	fetchExpositionEndpointMetadataSuccess,
	fetchExpositionAccessSuccess,
	deleteExpositionAccessSuccess,
	publicationActionSuccess,
	initPublishExposition,
	updateExpositionParams,
	refreshExpositionView,
	updateExpositionData,
	initExpositionView,
	refreshExpositionsList,
	bulkUpdateExpositionData,
	bulkPublishExposition,
	createExpositionFail,
	refreshAfterError,
	fetchAvailableExpositionsForMigrationSuccess,
	retrievesConsumersToMapSuccess,
	migrateSelectedExpositions,
	migrateSelectedExpositionsSuccess,
	migrateSelectedExpositionsFailed,
	fetchMigratedExpositionsListSuccess,
	fetchExpositionHistorySuccess,
	fetchExpositionOnHistoryNavigationSuccess,
	fetchJobErrorMessageSuccess,
	getJobErrorMessage,
	fetchJobErrorMessageFail,
	projectImportConsumersToMapSuccess,
	refreshDatablocksSuccess,
	fetchExpositionViewOnEndpointDetailsTriggerSuccess,
	fetchExpositionViewOnEndpointDetailsTrigger,
} from './expositions.actions';
import { ExpositionsState } from './expositions.state';

export const expositionsReducer = createReducer<ExpositionsState>(
	new ExpositionsState(),
	on(initExpositionsList, (state) => state.toggleLoading(true)),
	on(fetchAvailableExpositionsListSuccess, (state, action) =>
		state
			.toggleLoading(false)
			.setExpositionList(action.list)
			.setCanCreateExposition(action.canCreate)
	),
	on(fetchExpositionDataBlockListSuccess, (state, action) =>
		state
			.setDatablockList(action.datablocks)
			.setExpositionList(action.availableExpositions)
	),
	on(getDataBlocksColumns, (state) =>
		state.toggleLoadingDatablockColumns(true)
	),
	on(fetchDataBlockColumnsSuccess, (state, action) =>
		state
			.toggleLoadingDatablockColumns(false)
			.updateDatablockListWithColumns(action.datablockId, action.columns)
	),
	on(getColumnConfigList, (state) => state.toggleLoading(true)),
	on(fetchColumnConfigListSuccess, (state, action) =>
		state.setColumnConfigList(action.list).toggleLoading(false)
	),
	on(fetchAvailableTagsSuccess, (state, action) => state.setTags(action.tags)),
	on(fetchAvailableConsumersSuccess, (state, action) =>
		state.setAvailableConsumers(action.consumers)
	),
	on(createExposition, migrateSelectedExpositions, (state) =>
		state.toggleIsSavingExposition(true)
	),
	on(createExpositionSuccess, (state) => state.resetStepper()),
	on(
		createExpositionFail,
		migrateSelectedExpositionsSuccess,
		migrateSelectedExpositionsFailed,
		(state) => state.toggleIsSavingExposition(false).setRequestId('')
	),
	on(fetchExpositionSuccess, (state, action) =>
		state
			.setInViewExposition(action.exposition)
			.setExpositionList(action.availableExpositions)
			.setExpositionViewPermissions(action.permissions)
	),
	on(fetchExpositionColumnsSuccess, (state, action) =>
		state
			.setInViewExposition(state.inViewExposition.setColumns(action.columns))
			.setExpositionViewPermissions(action.permissions)
	),
	on(fetchExpositionMetadataSuccess, (state, action) =>
		state.setInViewExposition(
			state.inViewExposition.setMetadata(action.metadata)
		)
	),
	on(fetchExpositionEndpointMetadataSuccess, (state, action) =>
		state.setInViewExposition(
			state.inViewExposition.setEndpointMetadata(action.metadata)
		)
	),
	on(fetchExpositionAccessSuccess, (state, action) => {
		const { exposition } = action;
		const updated = exposition.setAccessPoints(
			exposition.accessPoints.setCurrentConfig(action.access)
		);
		return state
			.setInViewExposition(updated)
			.setExpositionViewPermissions(action.permissions);
	}),
	on(deleteExpositionAccessSuccess, (state, action) =>
		state.setInViewExposition(
			state.inViewExposition.setAccessPoints(
				state.inViewExposition.accessPoints.deleteAccess(action.accessId)
			)
		)
	),
	on(initPublishExposition, (state) => state.setActionInProgress('publish')),
	on(updateExpositionParams, (state) =>
		state
			.setActionInProgress('update')
			.setInViewExposition(
				state.inViewExposition.setPublishedApi(
					state.inViewExposition.published.addInProgressRowToUpdates()
				)
			)
	),
	on(updateExpositionData, (state) =>
		state
			.setActionInProgress('update-data')
			.setInViewExposition(
				state.inViewExposition.setPublishedApi(
					state.inViewExposition.published.addInProgressRowToUpdates()
				)
			)
	),
	on(bulkUpdateExpositionData, (state, action) =>
		state.setExpositionList(
			state.expositions.setRunningActionOn(action.expositionId, 'update-data')
		)
	),
	on(bulkPublishExposition, (state, action) =>
		state.setExpositionList(
			state.expositions.setRunningActionOn(action.expositionId, 'publish')
		)
	),
	on(
		initExpositionView,
		refreshExpositionView,
		publicationActionSuccess,
		refreshAfterError,
		(state) => state.setActionInProgress('none')
	),
	on(refreshDatablocksSuccess, (state, action) =>
		state.setDatablockList(action.datablocks)
	),
	on(publicationActionSuccess, (state, action) =>
		state.setInViewExposition(
			state.inViewExposition.setPublishedApi(
				state.inViewExposition.published.updateStatus(action.status)
			)
		)
	),
	on(refreshExpositionsList, (state, action) => {
		if (action.expositionId === undefined) {
			return state;
		}
		return state.setExpositionList(
			state.expositions.resetRunningActionOn(action.expositionId)
		);
	}),
	on(fetchAvailableExpositionsForMigrationSuccess, (state, action) =>
		state.setV1Expositions(action.eligible)
	),
	on(retrievesConsumersToMapSuccess, (state, action) =>
		state
			.setRequestId(action.requestId)
			.setUnmappedConsumers(action.unmappedConsumers)
			.setAvailableConsumers(action.availableConsumers)
	),
	on(projectImportConsumersToMapSuccess, (state, action) =>
		state
			.setRequestId('')
			.setUnmappedConsumers([])
			.setAvailableConsumers(action.availableConsumers)
	),
	on(fetchMigratedExpositionsListSuccess, (state, action) =>
		state.setMigrated(action.migrated)
	),
	on(fetchExpositionHistorySuccess, (state, action) =>
		state.setHistory(action.history)
	),
	on(fetchExpositionOnHistoryNavigationSuccess, (state, action) =>
		state.setInViewExposition(action.exposition)
	),
	on(getJobErrorMessage, (state) => state.setIsLoadingErrorLogs(true)),
	on(fetchJobErrorMessageFail, (state) => state.setIsLoadingErrorLogs(false)),
	on(fetchJobErrorMessageSuccess, (state, action) =>
		state
			.setHistory(
				state.history.updateJobLog(action.id.toString(), action.message)
			)
			.setIsLoadingErrorLogs(false)
	),
	on(fetchExpositionViewOnEndpointDetailsTriggerSuccess, (state, action) =>
		state
			.setInViewExposition(action.exposition)
			.toggleLoadingMetadataInfo(false)
	),
	on(fetchExpositionViewOnEndpointDetailsTrigger, (state) =>
		state.toggleLoadingMetadataInfo(true)
	)
);
