<ng-container *ngIf="vo$ | async; let vo">
	<app-page *ngIf="!vo.isLoading">
		<app-page-header></app-page-header>

		<app-page-actions>
			<button id="migrations-migrate-btn" mat-raised-button color="primary"
							(click)="goToMigrationsModalRoute()" i18n="@@exposition.migration.action">migrate</button>
		</app-page-actions>

		<app-page-content>
			<app-panel headerHeight="3rem" isAlwaysOpen="true"
								 hasSearchableGrid="true"
								 hasRefresh="true"
								 [itemsCount]="vo.list.elements.size"
								 (refresh)="refreshMigratedExpositionList()"
								 (search)="searchMigrated($event)">
				<span panel-title i18n="@@exposition.migrated.list">migrated</span>

				<dx-data-grid #expositionMigratedListDatagrid
											keyExpr="id"
											[elementAttr]="{
								id: 'exposition-migrated-list-datagrid',
								class: ''
							}"
											[repaintChangesOnly]="true"
											[dataSource]="vo.list.elements | toArray"
											[hoverStateEnabled]="true"
											[showColumnHeaders]="true"
											[allowColumnResizing]="true"
											[columnAutoWidth]="false"
											[showRowLines]="false"
											[showColumnLines]="false"
											[rowAlternationEnabled]="true"
											[showBorders]="false"
											[width]="'100%'">
					<dxo-scrolling mode="virtual"></dxo-scrolling>
					<!-- Fixable in position columns	-->
					<dxo-column-fixing [enabled]="true"></dxo-column-fixing>

					<dxo-load-panel
						shadingColor="rgba(0,0,0,0.4)"
						indicatorSrc="/src/img/loader-duotone.svg"
						[height]="100"
						[width]="100"
						[enabled]="true"
						[showIndicator]="true"
						[showPane]="true"
						[shading]="true"></dxo-load-panel>

					<ng-container
						*ngFor="let col of vo.list.availableColumns | toArray">
						<dxi-column
							*ngIf="col.field === 'oldExpositionLabel'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[visibleIndex]="col.visibleIndex"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="true"
							[allowFiltering]="col.isSearchable"
							[allowSorting]="true"
							[format]="col.format"
							[alignment]="'left'"
							[allowEditing]="false"
							[width]="'33%'"
							cellTemplate="sourceTemplate"></dxi-column>

						<dxi-column
							*ngIf="col.field === 'newExpositionLabel'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[visibleIndex]="col.visibleIndex"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowSorting]="true"
							[format]="col.format"
							[alignment]="'left'"
							[allowEditing]="false"
							[width]="'33%'"
							cellTemplate="targetTemplate"></dxi-column>

						<dxi-column
							*ngIf="col.field === 'migrationDate'"
							[dataField]="col.field"
							[caption]="col.alias"
							[groupIndex]="col.groupIdx"
							[visibleIndex]="col.visibleIndex"
							[fixed]="false"
							[allowResizing]="true"
							[allowHiding]="true"
							[allowGrouping]="true"
							[allowSearch]="col.isSearchable"
							[allowSorting]="true"
							[format]="col.format"
							[alignment]="'left'"
							[allowEditing]="false"
							[width]="'33%'"
							cellTemplate="migrationDateTemplate"></dxi-column>
					</ng-container>

					<div *dxTemplate="let cell of 'sourceTemplate'">
						<span>{{ cell.data['oldExpositionLabel'] }}</span>
					</div>

					<div *dxTemplate="let cell of 'targetTemplate'">
						<span>{{ cell.data['newExpositionLabel'] }}</span>
					</div>

					<div *dxTemplate="let cell of 'migrationDateTemplate'">
						<span>{{ cell.data['migrationDate'] | date: 'yyyy-MM-dd HH:mm' }}</span>
					</div>

					<dxo-group-panel [visible]="false"></dxo-group-panel>

					<!-- Search input -->
					<dxo-search-panel [visible]="false"></dxo-search-panel>

					<!-- Sorting	-->
					<dxo-sorting mode="multiple"></dxo-sorting>

					<!-- Pagination -->
					<dxo-paging [pageSize]="1000"></dxo-paging>

					<dxo-pager [visible]="false"></dxo-pager>
					<!-- State persistence	-->
					<dxo-state-storing
						type="localStorage"
						[enabled]="false"
						[storageKey]="dxLocalStorageKey"></dxo-state-storing>
				</dx-data-grid>

			</app-panel>
		</app-page-content>
	</app-page>
</ng-container>
